import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Tab,
  TextField,
  Grid,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";

import { CloseIcon, Visibility } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { useForm } from "../../common/useForms";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";

import { getChapterAll } from "../../redux/action/ChapterAction";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import {
  CreateBanner,
  DeleteBanner,
  GetAllBannerType,
  UpdateBanner,
} from "../../redux/action/BannerAction";
import { GetAllTransactions } from "../../redux/action/TransactionActions";
import SelectBox from "../../common/SelectBox";
import useUserRolePermissions from "../../common/UserPermissions";
import axios from "axios";
import { ApiURL } from "../../redux/common";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { base_Url } from "../../web/utils/common.js";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Loader from "../loader/Loader.js";

const statusOptions = [
  { label: "All", value: "all" },
  { label: "Successful", value: "successful" },
  { label: "Failed", value: "failed" },
  // { label: "Refunded", value: "refunded" },
];

function convertDateFormat(dateString) {
  // Parse the date string using the ISO 8601 format
  const dateObject = new Date(dateString);

  // Format the date object to the desired format
  const formattedDate = dateObject.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    //   second: "2-digit",
  });

  return formattedDate;
}

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const columns = [
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: true,
    label: "Mobile",
  },
  {
    id: "method",
    numeric: false,
    disablePadding: true,
    label: "Payment Method",
  },
  {
    id: "event_payment",
    numeric: false,
    disablePadding: true,
    label: "Event Payment",
  },
  {
    id: "membership_payment",
    numeric: false,
    disablePadding: true,
    label: "Membership Payment",
  },
  {
    id: "service_payment",
    numeric: false,
    disablePadding: true,
    label: "Service Payment",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Amount",
  },
  {
    id: "success_status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Transaction Date",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  // {
  //   id: "amount",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Amount",
  // },
].filter((el) => el !== null);

const initialValues = {
  banner_images: "",
  banner_type_id: "",
  chapter_id: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];
const initialFilterValues = {
  email: "",
  mobile_number: "",
  success_status: "all",
  month_year: "",
};

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [isFilterFormFilled, setIsFilterFormFilled] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPages, setRowsPerPages] = useState(10);

  const [editImageValue, setEditImageValue] = useState();
  const [downLoadData, setDownLoadData] = useState("");
  const [value, setValue] = useState("1");
  const [intensive, setIntensive] = useState([]);

  const [isFilterClicked, setIsFilterClicked] = useState(false);



  const [selectedDate, setSelectedDate] = useState("");

  const date = new Date(selectedDate);
  const formattedDate = dayjs(date).format("MM/YYYY");

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const columnsInt = [
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "user_name",
      numeric: false,
      disablePadding: false,
      label: "Referred User Name",
    },

    {
      id: "mobile_number",
      numeric: false,
      disablePadding: false,
      label: "Mobile Number",
    },

    ...(userData?.user?.user_role?.role === "Community Super Admin"
      ? [
        {
          id: "credited_to_email",
          numeric: false,
          disablePadding: true,
          label: "Credited To Email",
        },

        {
          id: "credited_to",
          numeric: false,
          disablePadding: true,
          label: "Referred By Name",
        },
      ]
      : []),

    {
      id: "intensive_amount",
      numeric: true,
      disablePadding: true,
      label: "Incentive Amount",
    },
    {
      id: "membership_name",
      numeric: false,
      disablePadding: true,
      label: "Incentive Type",
    },
    {
      id: "month_year",
      numeric: true,
      disablePadding: true,
      label: "Month/Year",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Date",
    },
  ].filter((el) => el !== null);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setPages(1)
  };

  const role = useUserRolePermissions();

  useEffect(() => {
    if (selectedDate) {
      setFilterDatas({
        ...filterDatas,
        'start_date': selectedDate,
      });
    }
  }, [selectedDate])

  const { loading, transaction } = useSelector(
    (state) => state?.TransactionReducer
  );



  useEffect(() => {
    dispatch(GetAllBannerType());
    dispatch(getChapterAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getChapterAll());
  }, [dispatch]);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    // dispatch(GetAllTransactions(page, rowPerPage));
  };

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "SubscriptionList";

  // useEffect(() => {
  //   if (!open) {
  //     dispatch(GetAllTransactions(page, rowsPerPage));
  //   }
  // }, [page, rowsPerPage, open, dispatch]);

  const getIntensiveDetials = async (rowPerPages = 10, pages = 1, monthYear) => {
    try {
      let incentiveType = "";
      if (value === "3") {
        incentiveType = "monthly";
      } else if (value === "2") {
        incentiveType = "membership";
      }

      const res = await axios.get(
        `${base_Url}user/my-intensive?limit=${rowPerPages}&page=${pages}&month_year=${monthYear || ""
        }&incentive_type=${incentiveType}`
      );

      if (res) {
        setIntensive(res?.data?.data);
      } else {
        // setError(true);
      }
    } catch (err) {
      // snackBar(err?.message || "API call failed");
    }
  };

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(
      (value) => value !== undefined || value !== ""
    );
    if (!allValuesSet) {
      getIntensiveDetials();
    }
  }, [pages, rowsPerPages]);

  // Update incentive type based on the value state
  useEffect(() => {
    getIntensiveDetials();
  }, [value]);

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(
      (value) => value !== undefined || value !== ""
    );

    let incentiveType = "";
    if (value === "3") {
      incentiveType = "monthly";
    } else if (value === "2") {
      incentiveType = "membership";
    }
    if (allValuesSet) {
      const email = filterDatas.email ? filterDatas.email : "";

      const mobile_number = filterDatas.mobile_number
        ? filterDatas.mobile_number
        : "";

      const success_status = filterDatas.success_status
        ? filterDatas.success_status !== "all"
          ? filterDatas.success_status
          : ""
        : "";

      const month_year = formattedDate !== 'Invalid Date' ? formattedDate : "";
      if (value !== "1") {
        const fetchSearchData = async () => {
          const res = await axios.get(
            `${base_Url}user/my-intensive?limit=${rowsPerPages}&page=${pages}&month_year=${month_year}&email=${email}&mobile_number=${mobile_number}&success_status=${success_status}&incentive_type=${incentiveType}`
          );

          if (res) {
            setIntensive(res?.data?.data);
          } else {
            // setError(true);
          }
        }
        fetchSearchData()
      }
    } else {
      dispatch(GetAllTransactions(page, rowsPerPage));
    }
  }, [pages, page, rowsPerPage])

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(
      (value) => value !== undefined || value !== ""
    );

    let incentiveType = "";
    if (value === "3") {
      incentiveType = "monthly";
    } else if (value === "2") {
      incentiveType = "membership";
    }
    if (allValuesSet) {
      const email = filterDatas.email ? filterDatas.email : "";

      const mobile_number = filterDatas.mobile_number
        ? filterDatas.mobile_number
        : "";

      const success_status = filterDatas.success_status
        ? filterDatas.success_status !== "all"
          ? filterDatas.success_status
          : ""
        : "";

      const month_year = formattedDate !== 'Invalid Date' ? formattedDate : "";
      if (value == "1") {
        dispatch(
          GetAllTransactions(
            page,
            rowsPerPage,
            email,
            mobile_number,
            success_status,
            incentiveType,
            month_year,
            email,
            mobile_number,
          )
        );
      }
    }
  }, [pages, page, rowsPerPage])


  const handleChangeRows = (event) => {
    const rowPerPages = event.target.value;
    setRowsPerPages(event.target.value);
    getIntensiveDetials(rowPerPages, "", "");
  };

  const handleChangePages = (event, value) => {
    setPages(value);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Banner");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (popupName === "Edit Banner") {
      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null ||
          fieldValues.banner_type_id?.trim() === ""
        ) {
          temp.banner_type_id = "Please enter banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    } else {
      if ("banner_images" in fieldValues) {
        if (
          fieldValues.banner_images === "" ||
          fieldValues.banner_images === undefined ||
          fieldValues.banner_images === null
        ) {
          temp.banner_images = "This field is required.";
        } else {
          temp.banner_images = "";
        }
      }

      if ("banner_type_id" in fieldValues) {
        if (
          fieldValues.banner_type_id === "" ||
          fieldValues.banner_type_id === undefined ||
          fieldValues.banner_type_id === null
        ) {
          temp.banner_type_id = "Please select banner type";
        } else {
          temp.banner_type_id = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Banner");
    if (data?.banner_images) {
      setEditImageValue(data?.banner_images);
    }
    setValues({
      ...values,
      banner_type_id: data?.banner_type || "",
      chapter_id: data?.chapter_id || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteBanner(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllTransactions(page, rowsPerPage));
      }
    });
  };

  
  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  const rows =
    transaction?.TransactionData && Array.isArray(transaction?.TransactionData)
      ? transaction?.TransactionData?.map((data) => {
        return {
          _id: data._id,
          email: subStr(data.email, 20),
          contact: data.contact,
          method: data.method,
          amount: data.amount || "-",
          event_payment: data.event_payment ? "Event" : "-",
          membership_payment: data.membership_payment ? "Membership" : "-",
          service_payment: data.service_payment ? "Service" : "-",
          success_status: data.success_status,
          createdAt: data.createdAt ? convertDateFormat(data.createdAt) : "-",
          view: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                onClick={() =>
                  navigate("/admin-transactions/view", {
                    state: { transactiondata: data },
                  })
                }
                sx={{
                  color: "black",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#153e80",
                  },
                  "&:focus": {
                    backgroundColor: "white",
                    color: "#153e80",
                    border: "none",
                  },
                }}
              >
                <Visibility />
              </IconButton>
            </div>
          ),
        };
      })
      : [];

  const rowsInt =
    intensive?.resultData && Array.isArray(intensive?.resultData)
      ? intensive?.resultData?.map((data) => {
        return {
          _id: data._id,
          user_name: data.user_name,
          intensive_amount: data.intensive_amount,
          email: data.email,
          mobile_number: data.mobile_number,
          month_year: data.month_year,
          membership_name: data.membership_name,
          credited_to: data.credited_to,
          credited_to_email: data.credited_to_email,
          createdAt: data.createdAt ? convertDateFormat(data.createdAt) : "-",
        };
      })
      : [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    let imagesArray = [];
    if (values.file instanceof FileList) {
      imagesArray = Array.from(values.file);
    } else {
      imagesArray = Array.from([values.file]);
    }
    imagesArray.forEach((image, index) => {
      formData.append(`banner_images`, image);
    });
    formData.append("banner_type", values.banner_type_id);
    formData.append("chapter_id", values.chapter_id);

    if (validate()) {
      if (popupName === "Edit Banner") {
        dispatch(UpdateBanner(selectedBanner._id, formData, handleClose));
      } else {
        dispatch(CreateBanner(formData, handleClose));
      }
    }
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };


  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    setSelectedDate()
    setIsFilterClicked(false)
    dispatch(GetAllTransactions(1, rowsPerPage, "", "", "", ""));
    getIntensiveDetials();
    closeFilterDrawer();
  };

  const [isNotValidEmail, setIsNotValidEmail] = useState()
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleSubmitFilter = async (e) => {
    e.preventDefault();
    setIsFilterClicked(true)
    let incentiveType = "";
    if (value === "3") {
      incentiveType = "monthly";
    } else if (value === "2") {
      incentiveType = "membership";
    }
    const body = {
      email: filterDatas.email,
      mobile_number: filterDatas.mobile_number,
      success_status: filterDatas.success_status,
      month_year: formattedDate,
    };
    setFilterDatas(body);

    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const success_status = filterDatas.success_status
      ? filterDatas.success_status !== "all"
        ? filterDatas.success_status
        : ""
      : "";

    const month_year = formattedDate !== 'Invalid Date' ? formattedDate : "";



    setPage(1);
    if (isValidEmail) {

      if (value !== "1") {
        const res = await axios.get(
          `${base_Url}user/my-intensive?limit=${rowsPerPages}&page=${pages}&month_year=${month_year}&email=${email}&mobile_number=${mobile_number}&success_status=${success_status}&incentive_type=${incentiveType}`
        );

        if (res) {
          setIntensive(res?.data?.data);
        } else {
          // setError(true);
        }
      } else {
        dispatch(
          GetAllTransactions(
            page,
            rowsPerPage,
            email,
            mobile_number,
            success_status,
            incentiveType,
            month_year,
            email,
            mobile_number,
          )
        );
      }
      // if (month_year) {
      //   getIntensiveDetials(month_year);
      // } else {

      // }
      closeFilterDrawer1();
    }
  };

  const DownLoadPdf = async () => {
    console.log("downLoadData_transaction_7878897", downLoadData)
    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const success_status = filterDatas.success_status
      ? filterDatas.success_status !== "all"
        ? filterDatas.success_status
        : ""
      : "";

    const month_year = formattedDate !== 'Invalid Date' ? formattedDate : "";
    try {
      const res = await axios.get(`${ApiURL}transaction/download-data?email=${email}&mobile_number=${mobile_number}&success_status=${success_status}&month_year=${month_year}`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const DownLoadPdfInt = async () => {
    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const success_status = filterDatas.success_status
      ? filterDatas.success_status !== "all"
        ? filterDatas.success_status
        : ""
      : "";

    const month_year = formattedDate !== 'Invalid Date' ? formattedDate : "";
    let incentiveType = "";
    if (value === "3") {
      incentiveType = "monthly";
    } else if (value === "2") {
      incentiveType = "membership";
    }
    try {
      const resInt = await axios.get(
        `${ApiURL}user/my-intensive?download_data=true&incentive_type=${incentiveType}&email=${email}&mobile_number=${mobile_number}&success_status=${success_status}&month_year=${month_year}`
      );
      setDownLoadData(resInt?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (value === "2" || value === "3") {
      DownLoadPdfInt();
    }
    if (downLoadData) {
      setIsFilterClicked(false)
    }
  }, [value, isFilterClicked]);

  useEffect(() => {
    DownLoadPdf();
    if (downLoadData) {
      setIsFilterClicked(false)
    }
  }, [isFilterClicked]);

  const isFilterFormFilledCheck = (filterData) => {
    return Object.keys(initialFilterValues).some((key) => {
      return filterData[key] !== initialFilterValues[key];
    });
  };

  const handleChange = (e) => {
    let temp = { ...errors };
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i

    if (name === "email" && value && !emailRegex.test(value)) {
      setIsNotValidEmail("Please enter valid email")
      setIsValidEmail(false)
    } else {
      setIsNotValidEmail()
      setIsValidEmail(true)
    }
    if (name === "mobile_number" && value && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
    // setFilterDatas((prevFilterData) => {
    //   const updatedFilterData = {
    //     ...prevFilterData,
    //     [name]: value,
    //   };
    //   setIsFilterFormFilled(isFilterFormFilledCheck(updatedFilterData));
    //   return updatedFilterData;
    // });
  };

  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "" || val !== "all");
      setIsFilterFormFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])

  // useEffect(() => {
  //   setIsFilterFormFilled(isFilterFormFilledCheck(filterDatas));
  // }, [filterDatas]);

  return (
    <Box sx={{ display: "block", }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={12} xl={12}>
          <PageHeader
            onFilterClick={openFilterDrawer}
            title="Transactions"
            onAddClick={handleClickOpen}
            isShowAdd={!!getPermissions(permissions.transaction, "is_add") && false}
            loading={loading}
            href={downLoadData}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={12} xl={12}>
          <Box sx={{ typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Transactions" value="1" sx={{ textTransform: 'capitalize' }} />
                  {userData?.user?.user_role?.role === "Community Super Admin" &&
                    <Tab label="Received Membership Incentive" value="2" sx={{ textTransform: 'capitalize' }} />
                  }
                  {userData?.user?.user_role?.role === "Community Super Admin" &&
                    <Tab label="Monthly Incentive" value="3" sx={{ textTransform: 'capitalize' }} />
                  }
                </TabList>
              </Box>
              <TabPanel sx={{ padding: 0 }} value="1">
                {loading ? (
                  <Loader />
                ) : (
                  <Box sx={{ width: "95%", margin: "auto", overflowX: "auto" }}>
                    <EnhancedTable
                      rowsPerPage={rowsPerPage}
                      key={rows._id}
                      columns={columns}
                      rows={rows}
                      order={order}
                      orderBy={orderBy}
                      selected={selected}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      title={tableTitle}
                      tableList={transaction?.TransactionData}
                    />
                  </Box>
                )}
              </TabPanel>
              {userData?.user?.user_role?.role === "Community Super Admin" && (
                <>
                  <TabPanel sx={{ padding: 0 }} value="2">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Box sx={{ width: "95%", margin: "auto", overflowX: "auto" }}>
                        <EnhancedTable
                          rowsPerPage={rowsPerPage}
                          key={rows._id}
                          columns={columnsInt}
                          rows={rowsInt}
                          order={order}
                          orderBy={orderBy}
                          selected={selected}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          title={tableTitle}
                          tableList={intensive?.resultData}
                        />
                      </Box>
                    )}
                  </TabPanel>

                  <TabPanel sx={{ padding: 0 }} value="3">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Box sx={{ width: "95%", margin: "auto", overflowX: "auto" }}>
                        <EnhancedTable
                          rowsPerPage={rowsPerPage}
                          key={rows._id}
                          columns={columnsInt}
                          rows={rowsInt}
                          order={order}
                          orderBy={orderBy}
                          selected={selected}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          title={tableTitle}
                          tableList={intensive?.resultData}
                        />
                      </Box>
                    )}
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Box>

          <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
            <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
              <Box className={classes.mainFilterBox}>
                <Stack className={classes.stackFilter}>
                  <Typography className={classes.typographyFilter}>
                    Filter
                  </Typography>

                  <CloseIcon
                    className="close-button"
                    onClick={closeFilterDrawer1}
                  />
                </Stack>
                <Box
                  sx={{
                    marginTop: "5px",
                  }}
                >
                  <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                </Box>

                <div>
                  <Box>
                    <Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="email"
                      value={filterDatas.email}
                      onChange={handleChange}
                      maxLength={200}
                    />
                    <p style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }} className="err-msg err-msg-font">{isNotValidEmail}</p>
                  </Box>
                  <Box>
                    <Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Mobile"
                      name="mobile_number"
                      value={filterDatas.mobile_number}
                      onChange={handleChange}
                      maxLength={10}
                    />
                  </Box>
                  {value === "1" || value === "3" || value === "2" ? null :
                    <Box>
                      <SelectBox
                        name="success_status"
                        label="Status"
                        ClassName="status-select-filter"
                        value={filterDatas.success_status}
                        onChange={handleChange}
                        options={statusOptions}
                      />
                    </Box>}
                  <Box sx={{ marginTop: "12px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Month Year"
                        openTo="month"
                        views={["year", "month"]}
                        format="MM/YYYY"
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className="custom-date-picker"
                      />
                    </LocalizationProvider>
                  </Box>

                  <Stack className={classes.buttonFilter}>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="cancel-filter"
                      onClick={resetFormFilter}
                      disabled={!isFilterFormFilled}
                    >
                      Reset
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      className="order-filter-button"
                      type="submit"
                      disabled={!isFilterFormFilled}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Filter
                    </Button>
                  </Stack>
                </div>
              </Box>
            </form>
          </Drawer>
          {value === "2" || value === "3" ? (
            <Box
              sx={{
                display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
                marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                mt: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                  gap: 2,
                  width: { sm: "100%", md: "50%" },
                }}
                display="flex"
                alignItems="center"
              >
                <Box>
                  Showing {intensive?.currentPage} of {intensive?.totalPages}{" "}
                  Results
                </Box>
                <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                  <Typography>Records per page</Typography>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="limites"
                      name="limites"
                      value={rowsPerPages}
                      onChange={handleChangeRows}
                      displayEmpty
                      sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {RecordPerPage?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name || item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Pagination
                count={intensive?.totalPages}
                page={pages}
                onChange={handleChangePages}
                variant="outlined"
                shape="rounded"
                sx={{
                  marginRight:"15px",
                  "& .MuiButtonBase-root ": {
                    color: "#000 !important",
                  },
                  "& .Mui-selected ": {
                    background: "#4b4b4e70 !important",
                  },
                  "& .Mui-disabled ": {
                    background: "#6c696966 !important",
                    color: "#000000 !important",
                    opacity: 1,
                  },
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
                marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                mt: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                  gap: 2,
                  width: { sm: "100%", md: "50%" },
                }}
                display="flex"
                alignItems="center"
              >
                <Box>
                  Showing {transaction?.currentPage} of {transaction?.totalPages}{" "}
                  Results
                </Box>
                <Box display="flex" sx={{ gap: 1 }} alignItems="center">
                  <Typography>Records per page</Typography>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="limites"
                      name="limites"
                      value={rowsPerPage}
                      onChange={handleChangeRow}
                      displayEmpty
                      sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {RecordPerPage?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name || item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Pagination
                count={transaction?.totalPages}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{
                  marginRight:"15px",
                  "& .MuiButtonBase-root ": {
                    color: "#000 !important",
                  },
                  "& .Mui-selected ": {
                    background: "#4b4b4e70 !important",
                  },
                  "& .Mui-disabled ": {
                    background: "#6c696966 !important",
                    color: "#000000 !important",
                    opacity: 1,
                  },
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionList;
