import React, { useEffect, useState } from "react";
import BlogImage5 from "../../assets/image/blog/image-5.jpg";
import BlogCard from "../blog/BlogCard";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { GetAllEvent } from "../../redux/eventAction";
import Loader from "../../components/loader/Loader";
import NotFound from "../notFound/NotFound";
import EventCard from "../../components/eventCard/EventCard";
import { Link } from "react-router-dom";
import NotDataFound from "../notFound/NotDataFound";
import axios from "axios";
import { ApiURL } from "../../../redux/common";
import { getAllService } from "../../../redux/action/ServiceAction";
import { useDispatch, useSelector } from "react-redux";
import ServiceCard from "./ServiceCard";
import Login from "../auth/Login";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#ff5a5f",
      border: "none",
      "&:hover": {
        color: "#ff5a5f",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#ff5a5f",
    },
  },
}));

const cardPerPage = 9;

const Service = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { service, loading, ...rest } = useSelector(
    (state) => state?.ServiceReducer
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    setLoginModalOpen(false);
  };
  useEffect(() => {
    dispatch(getAllService(currentPage, "", cardPerPage));
  }, [currentPage]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotDataFound />;
  }

  const handleCurrentPageChange = (_, val) => {
    setCurrentPage(val);
  };
  return (
    <>
      <div
        id="page-title"
        className="page-title page-title-style-background"
        style={{ backgroundImage: `url(${BlogImage5})` }}
      >
        <div className="container">
          <div className="h-100 d-flex align-items-center">
            <div className="mb-2">
              <h1
                className="mb-0 fadeInDown animated"
                data-animate="fadeInDown"
              >
                <span>Services </span>
              </h1>
              <ul
                className="breadcrumb breadcrumb-style-01 fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li className="breadcrumb-item">
                  <Link to="/" className="link-hover-dark-primary">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span>Services</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="wrapper-content" className="wrapper-content">
        <div className="container">
          <div className="page-container">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all"
              >
                <div className="row">
                  {service &&
                    service?.serviceData?.map((item) => {
                      return <ServiceCard handleModalOpen={handleModalOpen} item={item} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {service?.totalPages > 1 && (
          <Pagination
            count={service?.totalPages}
            page={currentPage}
            className={classes.pagination}
            shape="rounded"
            onChange={handleCurrentPageChange}
          />
        )}
      </div>
      <Login open={loginModalOpen} handleClose={handleModalClose} />
    </>
  );
};

export default Service;
