import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Link as MuiLink,
} from "@mui/material";

const BaseBlogCard = ({ item }) => {
  const isVideo = (url) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = url.split(".").pop();
    return videoExtensions.includes(extension);
  };

  const removeHtmlTags = (str) => {
    if (!str) return "";
    return str.replace(/<[^>]*>/g, "");
  };

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }

  return (
    <Box sx={{ flex: "0 0 auto", width: {xs:"100%",sm:"33%"}, p: 1 }}>
      <Card sx={{ border: "none", boxShadow: "none" }}>
        <MuiLink
          component={Link}
          to={`/blog/` + item?._id}
          sx={{ textDecoration: "none" }}
        >
          {isVideo(item?.blog_images[0]) ? (
            <CardMedia
              component="video"
              src={item?.blog_images[0]}
              sx={{ height: 300, objectFit: "cover" }}
            />
          ) : (
            <CardMedia
              component="img"
              image={item?.blog_images[0]}
              alt="product 1"
              sx={{ height: 300, objectFit: "cover" }}
            />
          )}
        </MuiLink>
        <CardContent sx={{ p: 0,  mt:1, ml:{xs:2}}}>
          <Typography
            variant="h5"
            sx={{ fontSize: 18, letterSpacing: "0.025em", mb: 1 }}
          >
            <MuiLink
              component={Link}
              to={`/blog/` + item?._id}
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              {item?.title}
            </MuiLink>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: 14, letterSpacing: "0.025em", mb: 1 }}
          >
            {subStr(removeHtmlTags(item?.description), 150)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {moment(item?.createdAt).format("MMMM Do YYYY")} by
            </Typography>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {item?.user_name}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BaseBlogCard;
