import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetAllWithdraw =
  (rowsPerPage, page, status) => async (dispatch) => {
    try {
      dispatch({
        type: "WITHDRAW_GET_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}withdraw-request/get-all?limit=${rowsPerPage}&page=${page}&status=${status}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "WITHDRAW_GET_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "WITHDRAW_GET_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "WITHDRAW_GET_LOADING",
        payload: false,
      });
    }
  };

export const CreateWithdraws = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_WITHDRAW",
      payload: true,
    });

    const res = await axios.post(
      `${ApiURL}withdraw-request/make-withdraw-request`,
      data
    );

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_WITHDRAW_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    } else {
      snackBar(res?.data?.message, res?.data?.status);
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "CREATE_LOADING_WITHDRAW",
      payload: false,
    });
  }
};

export const UpdateWithdraw = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_WITHDRAW",
      payload: true,
    });

    const res = await axios.put(`${ApiURL}withdraw-request/update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_WITHDRAW_SUCCESS",
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_WITHDRAW",
      payload: false,
    });
  }
};

export const DeleteWithdraw = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_WITHDRAW_LOADING",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}withdraw-request/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_WITHDRAW_LOADING",
        payload: false,
      });
      dispatch({
        type: "DELETE_WITHDRAW_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
    snackBar(res?.data?.message, res?.data?.status);
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status);
    console.error(err);
    dispatch({
      type: "DELETE_WITHDRAW_LOADING",
      payload: false,
    });
  }
};

export const SendWithdraw = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SEND_LOADING_NOTIFICATION",
      payload: true,
    });

    const res = await axios.post(`${ApiURL}notification/send`, data);

    if (res.data.status === true) {
      dispatch({
        type: "SEND_NOTIFICATION_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status);
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.message, err?.response?.status);
  } finally {
    dispatch({
      type: "SEND_LOADING_NOTIFICATION",
      payload: false,
    });
  }
};

export const GetAllRequestUserWithdraw =
  (rowsPerPage, page, status) => async (dispatch) => {
    try {
      dispatch({
        type: "WITHDRAWUSER_GET_LOADING",
        payload: true,
      });

      const res = await axios.get(
        `${ApiURL}withdraw-request/get-request?limit=${rowsPerPage}&page=${page}&status=${status}`
      );

      if (res.data.status === true) {
        const responseData =
          res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "WITHDRAWUSER_GET_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "WITHDRAWUSER_GET_SUCCESS",
          payload: [],
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "WITHDRAW_GET_LOADING",
        payload: false,
      });
    }
  };
