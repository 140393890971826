import React, { useEffect, useState } from "react";
// import logo from "../../assets/logo-home.png";
import { Typography, makeStyles } from "@material-ui/core";
import logo from "../../assets/image/logo-home.svg";
import Login from "../../pages/auth/Login";
import {
  Box,
  Button,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropdownMenuItem from "../DropdownMenuItem/DropdownMenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  height: "220px",
  borderRadius: "20px",
}));

const pages = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Be a Partner",
    path: "/be-partner",
  },
  {
    title: "Be a Member",
    path: "/be-member",
  },
  {
    title: "More",
    children: [
      {
        title: "About",
        path: "/about-us",
      },
      {
        title: "Blog",
        path: "/blog",
      },
      {
        title: "Contact",
        path: "/contact-us",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "12rem",
    // marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "8rem",
    },
    marginTop: "0.2em",
  },
}));

const headerBackgroundWhiteOnPath = ["about-us", "contact-us", "event"];
const textWhiteOnPath = [""];

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useLocation();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(location && location.state && location.state.formName === "Reset password" ? true : false);
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [show, setShow] = useState(true);
  const [openLog, setOpenLog] = useState(false);
 
  useEffect(() => {
    if(location && location.state && location.state.formName === "Reset password"){
      window.history.replaceState({}, '')
    }
  }, [location])

  const handleClickOpenLog = () => {
    setOpenLog(true);
  };
  const handleCloseLog = () => {
    setOpenLog(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos < prevScrollPos) {
        setShow(true);
      } else {
        setShow(false);
      }
      setIsTop(currentScrollPos <= 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const token = localStorage.getItem("access_token");

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };

  const handleNavigate = (path) => {
    handleCloseNavMenu();
    navigate(path);
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("userData");
    window.location.reload();
  };

  return (
    <>
      <header
        id="header"
        className="main-header header-sticky header-sticky-smart header-style-04 header-float header-light text-uppercase header-position-change"
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 999,
          width: "100%",
          transition: "all 0.3s ease-in-out",
          backgroundColor: "#fff",
          boxShadow: isTop || show ? "none" : "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="header-wrapper sticky-area">
          <div className="container">
            <div
              className="topbar d-flex"
              style={{
                marginTop: "2px",
                paddingLeft: "10px",
                paddingRight: "18px",
              }}
            >
              {/* ml-auto d-flex align-items-center */}
              <div className="topbar-item topbar-item-left d-flex align-items-center">
                <div className="social-icon">
                  <ul className="list-inline">
                    <li className="list-inline-item mr-5">
                      <a
                        target="_blank"
                        title="Instagram"
                        href="https://www.instagram.com/myggcommunity?igsh=cWR4Znc3NjBmcmht"
                      >
                        <InstagramIcon
                          sx={{
                            color: "#585858",
                            "&:hover": {
                              color: "black !important",
                            },
                          }}
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a
                        target="_blank"
                        title="Youtube"
                        href="https://youtube.com/@GlobalGarnerOfficial?si=pLbPk5lraRX0Zog6"
                      >
                        <YouTubeIcon
                          sx={{
                            color: "#585858",
                            "&:hover": {
                              color: "black !important",
                            },
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="topbar-item ml-auto d-flex align-items-center">
                <div className="item">
                  <i className="fal fa-envelope"></i>&#x2002;
                  <a href="mailto:info@globalgarner.community">
                    <span
                      style={{ textTransform: "lowercase", color: "black" }}
                    >
                      info@globalgarner.community
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-xl">
              <div className="header-mobile d-flex d-xl-none flex-fill justify-content-between align-items-center">
                <Box>
                  <MenuIcon
                    onClick={handleOpenNavMenu}
                    sx={{ display: { md: "block", xs: "block" } }}
                  />
                </Box>
                <a className="navbar-brand navbar-brand-mobile" href="/">
                  <img
                    className={"logo-image " + classes.logo}
                    src={logo}
                    alt="GGC"
                  />
                </a>

                <Box
                  sx={{
                    flexGrow: { xs: 1, md: 1 },
                  }}
                >
                  <SwipeableDrawer
                    sx={{ display: { xs: "flex", md: "flex" } }}
                    anchor="top"
                    open={anchorElNav}
                    onClose={handleCloseNavMenu}
                    onOpen={handleCloseNavMenu}
                  >
                    <Box
                      role="presentation"
                      // onClick={handleCloseNavMenu}
                      onKeyDown={handleCloseNavMenu}
                      sx={{
                        width: "fit-content",
                        marginTop: 2,
                        minWidth: "11rem",
                      }}
                    >
                      <List>
                        {pages.map((page, index) => (
                          <ListItem
                            key={index}
                            disablePadding
                            sx={{
                              fontSize: 15,
                              fontWeight: "bold",
                              marginLeft: "12px",
                              // ...(hoverStyles),
                            }}
                          >
                            {Array.isArray(page.children) ? (
                              <DropdownMenuItem
                                handleCloseNavMenu={handleCloseNavMenu}
                                title={page.title}
                                items={page.children}
                              />
                            ) : (
                              <ListItemButton
                                onClick={() => handleNavigate(page.path)}
                              >
                                <ListItemText primary={page.title} />
                              </ListItemButton>
                            )}
                          </ListItem>
                        ))}
                        {token && (
                          <ListItemButton
                            onClick={() => handleNavigate("/admin/dashboard")}
                            sx={{
                              fontSize: 15,
                              fontWeight: "bold",
                              marginLeft: "12px",
                            }}
                          >
                            <ListItemText primary={"Dashboard"} />
                          </ListItemButton>
                        )}
                      </List>
                    </Box>
                  </SwipeableDrawer>
                </Box>
                <a
                  className="mobile-button-search"
                  href="#search-popup"
                  data-gtf-mfp="true"
                  data-mfp-options='{"type":"inline","mainClass":"mfp-move-from-top mfp-align-top search-popup-bg","closeOnBgClick":false,"showCloseBtn":false}'
                >
                  {!token ? (
                    <svg
                      onClick={handleClickOpen}
                      className="icon icon-user-circle-o"
                    >
                      <use xlinkHref="#icon-user-circle-o"></use>
                    </svg>
                  ) : (
                    <LogoutIcon onClick={handleClickOpenLog} />
                  )}
                </a>
              </div>
              <div className="collapse navbar-collapse" id="navbar-main-menu">
                <a className="navbar-brand d-none d-xl-block mr-auto" href="/">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                  >
                    <img src={logo} alt="GGC" className={classes.logo} />
                  </Typography>
                </a>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${pathname === '/be-partner' ? 'active' : ''}`} to="/be-partner">
                      Be a Partner
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${pathname === '/be-member' ? 'active' : ''}`} to="/be-member">
                      Be a member
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/"
                      onClick={(e) => e.preventDefault()}
                    >
                      More
                      <span className="caret">
                        <i className="fas fa-angle-down"></i>
                      </span>
                    </Link>
                    <ul className="sub-menu x-animated x-fadeInUp">
                      <li className="nav-item">
                        <Link className={`nav-link ${pathname === '/about-us' ? 'active' : ''}`} to="/about-us">
                          About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link ${pathname === '/blog' ? 'active' : ''}`} to="/blog">
                          Blog
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link ${pathname === '/contact-us' ? 'active' : ''}`} to="/contact-us">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {token && (
                    <li className="nav-item">
                      <Link className={`nav-link ${pathname === '/admin/dashboard' ? 'active' : ''}`} to="/admin/dashboard">
                        Dashboard
                      </Link>
                    </li>
                  )}
                </ul>
                <div className="mfp-hide">
                  <div className="form-login-register">
                    <div className="tabs mb-8">
                      <ul
                        className="nav nav-pills tab-style-01 text-capitalize justify-content-center"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="login-tab"
                            data-toggle="tab"
                            href="#login"
                            role="tab"
                            aria-controls="login"
                            aria-selected="true"
                          >
                            <h3>Log In</h3>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="header-customize justify-content-end align-items-center d-none d-xl-flex">
                  {token ? (
                    <div className="header-customize-item mr-3">
                      <a
                        onClick={handleClickOpenLog}
                        className="link"
                        data-gtf-mfp="true"
                        data-mfp-options='{"type":"inline"}'
                        style={{ cursor: "pointer" }}
                      >
                        <LogoutIcon />
                        LogOut
                      </a>
                    </div>
                  ) : (
                    <div className="header-customize-item mr-3">
                      <a
                        href="#"
                        onClick={handleClickOpen}
                        className="link"
                        data-gtf-mfp="true"
                        data-mfp-options='{"type":"inline"}'
                      >
                        <svg className="icon icon-user-circle-o">
                          <use xlinkHref="#icon-user-circle-o"></use>
                        </svg>
                        Log in
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleCloseLog}
          aria-labelledby="customized-dialog-title"
          open={openLog}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div>
            <Box
              sx={{
                width: "320px",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography style={{ marginTop: "27px", padding: "13px" }}>
                Are you sure you want to logout?
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseLog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <Button
                      onClick={handleLogOut}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "0",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        color: "#fff",
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        ":hover": { bgcolor: "#1976d2", border: "0" },
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        textTransform: "none",
                        minWidth: "100px",
                      }}
                      onClick={handleCloseLog}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </div>
        </BootstrapDialog>
      </header>
      {location && location.state && location.state.formName === "Reset password" ?
        <Login toNav={true} open={open} handleClose={handleClose} />
        :
        <Login toNav={true} open={open} handleClose={handleClose} />
      }
    </>
  );
};

export default Navbar;
