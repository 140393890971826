import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { CloseIcon, Visibility } from "../../assets/icons/Icons";
import "../../components/table/table.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import SelectBox from "../../common/SelectBox";
import { FormControl } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import { ApiURL, snackBar } from "../../redux/common";
import axios from "axios";
import useUserRolePermissions from "../../common/UserPermissions";

import Loader from "../loader/Loader";
import { GetAllUser, GetUserRole } from "../../redux/action/UserManageAction.js";
import { useForm } from "../../common/useForms.js";

const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
];

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
  customCircularProgress: {
    width: "25px !important",
    height: "25px !important",
    color: "#fff !important",
  },
}));

const initialValues = {
  email: "",
  role: "",
  mobile_number: "",
  password: "",
  confirm_password: "",
};

const initialFilterValues = {
  // page: 1,
  email: "",
  mobile_number: "",
  role_id: "",
  gender: "",
  approval_status: "",
  // rowsPerPage: 10,
};

const genderOption = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Transgender", value: "transgender" },
  { label: "Other", value: "other" },
];

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const LeadList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [selectedUser, setSelectedUser] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downLoadData, setDownLoadData] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  // const [userallData, setuserallData] = useState([]);

  const location = useLocation();

  const DownLoadPdf = async () => {
    try {
      const res = await axios.get(`${ApiURL}user/download-data`);
      setDownLoadData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching role data:", error);
      return {};
    }
  };

  useEffect(() => {
    DownLoadPdf();
  }, []);

  const isEditAllowed = getPermissions(permissions.user, "is_edit");

  const chapters = useSelector(
    (state) => state?.ChapterReducer?.chapter?.chapterData
  );
  const role = useSelector((state) => state?.UserManageReducer?.roleData);

  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);

  const columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "mobile_number",
      numeric: true,
      disablePadding: false,
      label: "Mobile Number",
    },
    {
      id: "gender",
      numeric: true,
      disablePadding: false,
      label: "Gender",
    },
    {
      id: "role",
      numeric: true,
      disablePadding: false,
      label: "Role",
    },
    userData?.user?.user_role?.role === "Chapter President"
      ? {
        id: "intensive_amount",
        numeric: true,
        disablePadding: false,
        label: "Intensive Amount",
      }
      : null,
    {
      id: "payment_status",
      numeric: true,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
  ].filter((el) => el !== null);

  const handleChangeRow = (event) => {
    const rowPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    const name = filterDatas.name ? filterDatas.name : "";

    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const gender = filterDatas.gender ? filterDatas.gender : "";

    const role_id = filterDatas.role_id ? filterDatas.role_id : "";

    const approval_status = filterDatas.approval_status
      ? filterDatas.approval_status
      : "";

    setPage(1);
    dispatch(
      GetAllUser(
        1,
        email,
        mobile_number,
        "",
        role_id,
        gender,
        approval_status,
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        "",
        "",
        "",
        name,
        rowPerPage,
      )

    );
  };

  const { userallData, loading, updateLoading, deleteLoading, createLoading, roleData } =
    useSelector((state) => state.UserManageReducer);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "LeadList";

  useEffect(() => {
    const allValuesSet = Object.values(filterDatas).every(value => value !== undefined || value !== '');

    if (allValuesSet) {
      const name = filterDatas.name ? filterDatas.name : "";

      const email = filterDatas.email ? filterDatas.email : "";

      const mobile_number = filterDatas.mobile_number
        ? filterDatas.mobile_number
        : "";

      const gender = filterDatas.gender ? filterDatas.gender : "";

      const role_id = filterDatas.role_id ? filterDatas.role_id : "";

      const approval_status = filterDatas.approval_status
        ? filterDatas.approval_status
        : "";

      // setPage(1);
      dispatch(
        GetAllUser(
          page,
          email,
          mobile_number,
          "",
          role_id,
          gender,
          approval_status,
          "",
          "",
          "",
          "",
          "",
          "",
          false,
          "",
          "",
          "",
          name,
          rowsPerPage,
        )

      );
    } else {
      // getAllUsers(page);
      dispatch(GetAllUser(
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        "",
        "",
        "",
        "",
        10
      ))
    }
  }, [page]);

  useEffect(() => {
    dispatch(GetUserRole());
  }, [dispatch]);


  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [getAllUsersData, setGetAllUsersData] = useState()

  const getAllUsers = async () => {
    try {
      const res = await axios.get(
        `${ApiURL}user/all-users?limit=${rowsPerPage || 10
        }&page=${page}&subscriber=false`
      );

      if (res.status === 200) {
        setGetAllUsersData(res.data.data)
        // setuserallData(res.data.data);
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  };



  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    // rowsPerPage
    dispatch(GetAllUser(1,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      "",
      "",
      "",
      "",
      "",));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const [isNotValidEmail, setIsNotValidEmail] = useState()
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i

    if (name === "email" && value && !emailRegex.test(value)) {
      setIsNotValidEmail("Please enter valid email")
      setIsValidEmail(false)
    } else {
      setIsNotValidEmail()
      setIsValidEmail(true)
    }

    if (name === "mobile_number" && value && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const [isFieldFilled, setIsFieldFilled] = useState()

  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "");
      setIsFieldFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])

  const handleLocationChange = (name, value) => {
    setFilterDatas((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleSubmitFilter = async (e) => {
    e.preventDefault();

    const body = {
      name: filterDatas.name,
      email: filterDatas.email,
      mobile_number: filterDatas.mobile_number,
      role_id: filterDatas.role_id,
      gender: filterDatas.gender,
      approval_status: filterDatas.approval_status,
    };
    setFilterDatas(body);

    const name = filterDatas.name ? filterDatas.name : "";

    const email = filterDatas.email ? filterDatas.email : "";

    const mobile_number = filterDatas.mobile_number
      ? filterDatas.mobile_number
      : "";

    const gender = filterDatas.gender ? filterDatas.gender : "";

    const role_id = filterDatas.role_id ? filterDatas.role_id : "";

    const approval_status = filterDatas.approval_status
      ? filterDatas.approval_status
      : "";

    setPage(1);
    if (isValidEmail) {
      const res = await dispatch(
        GetAllUser(
          1,
          email,
          mobile_number,
          "",
          role_id,
          gender,
          approval_status,
          "",
          "",
          "",
          "",
          "",
          "",
          false,
          "",
          "",
          "",
          name,
          10,
        )
      );
      if (res.data.status === false) {
        snackBar(res.data.message, res.data.status);
      } else {
        closeFilterDrawer1();
      }
      // getAllUsers(page);
    }
  };

  const rows =
    userallData !== undefined && Array.isArray(userallData?.userData)
      ? userallData?.userData?.map((user) => {
        const selected = user === selectedUser;
        return {
          _id: user._id,
          name: user.first_name + " " + user.last_name,
          mobile_number: user.mobile_number,
          email: user.email,
          gender: user.gender,
          intensive_amount: user.intensive_amount || "-",
          role: user.role,
          status: user.approval_status || "Pending",
          payment_status: "Pending",
          selected: selected,
        };
      })
      : [];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in|co)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";
    if ("mobile_number" in fieldValues) {
      if (fieldValues.mobile_number !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobile_number)) {
          temp.mobile_number = "";
        } else {
          temp.mobile_number = "Only Number allow";
        }
      } else {
        temp.mobile_number = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const fields = [
    {
      id: "email",
      label: "Email Address",
      type: "email",
      value: values.email,
      name: "email",
      errors: errors.email,
      maxLength: 50,
    },
    {
      id: "role",
      label: "Role",
      type: "select",
      options:
        roleData &&
        roleData?.roleData?.map((el) => {
          return { value: el._id, label: el.role };
        }),
      value: values.role,
      name: "role",
      errors: errors.role,
      maxLength: 100,
    },
    {
      id: "mobile_number",
      label: "Phone Number",
      value: values.mobile_number,
      name: "mobile_number",
      errors: errors.mobile_number,
      maxLength: 10,
    },
  ];
  const isAnyFieldFilled = Object.values(filterDatas).some((value) =>
    Boolean(value)
  );

  const handleChipClick = (data) => {
    setStatusModal(data);
  };

  const handleModalClose = () => {
    setStatusModal(false);
  };

  return (
    <Box>
      <PageHeader title="Lead List" onFilterClick={openFilterDrawer} isShowAdd={false} />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={rows._id}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={userallData?.userData}
          handleChipClick={isEditAllowed ? handleChipClick : () => { }}
        />
      )}

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {userallData?.currentPage} of {userallData?.totalPages}{" "}
            Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ minWidth: "4rem", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={userallData?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
      </Box>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
          <Box className={classes.mainFilterBox}>
            <Stack className={classes.stackFilter}>
              <Typography className={classes.typographyFilter}>
                Filter
              </Typography>

              <CloseIcon
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>
            <Box
              sx={{
                marginTop: "5px",
              }}
            >
              <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
            </Box>

            <div className="pt-2">
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.name}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                  name="name"
                  value={filterDatas.name}
                  onChange={handleChange}
                  maxLength={200}
                />
              </Box>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.email}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                  name="email"
                  value={filterDatas.email}
                  onChange={handleChange}
                  maxLength={200}
                />
                <p style={{ marginLeft: "10px", marginTop: "0px", marginBottom: "0px" }} className="err-msg err-msg-font">{isNotValidEmail}</p>
              </Box>
              <Box>
                <Input
                  disabled={isAnyFieldFilled && !filterDatas.mobile_number}
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Mobile"
                  name="mobile_number"
                  value={
                    filterDatas?.mobile_number || filterDatas?.mobile_number
                  }
                  onChange={handleChange}
                  maxLength={10}
                />
              </Box>


              <Box>
                <SelectBox
                  value={filterDatas?.gender}
                  name="gender"
                  label="Gender"
                  ClassName="status-select-filter"
                  onChange={handleChange}
                  options={genderOption}
                />
              </Box>
              <Box>
                <SelectBox
                  name="role_id"
                  label="Role"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.role_id}
                  onChange={handleChange}
                  options={role.roleData}
                />
              </Box>

              <Box>
                <SelectBox
                  name="approval_status"
                  label="Status"
                  // disabled={isAnyFieldFilled && !filterDatas.status}
                  ClassName="status-select-filter"
                  value={filterDatas.approval_status}
                  onChange={handleChange}
                  options={statusOptions}
                />
              </Box>

              <Stack className={classes.buttonFilter}>
                <Button
                  disabled={!isFieldFilled}
                  color="secondary"
                  size="large"
                  variant="contained"
                  className="cancel-filter"
                  onClick={resetFormFilter}
                >
                  Reset
                </Button>
                <Button
                  disabled={!isFieldFilled}
                  color="primary"
                  size="large"
                  variant="contained"
                  className="order-filter-button"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </div>
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default LeadList;
