import { Grid } from "@mui/material";
import React from "react";

const JoinWithUs = ({ joinWithUs, navigateToMember, navigateToPartner }) => {
  return (
    <div>
      {joinWithUs &&
        joinWithUs?.map((item, i) => {
          if (i % 2 == 0) {
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
                sx={{ paddingTop: "2em" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      minHeight: "100px",
                      maxHeight: "340px",
                    }}
                    src={item.img}
                    alt=""
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{ padding: "2em" }}
                >
                  <h5 style={{ marginBottom: "1em" }}>{item.title}</h5>
                  <p style={{ color: "gray", textAlign: "justify" }}>
                    {item.desc}
                  </p>
                  {item.btn_name ? (
                    <button
                      style={{
                        width: "100%",
                        marginBottom: "1em",
                        background: "rgb(28,63,119)",
                        borderColor: "rgb(28,63,119)",
                      }}
                      onClick={() => navigateToMember()}
                    >
                      {item.btn_name}
                    </button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
                sx={{ paddingTop: "2em" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{ padding: "2em" }}
                >
                  <div>
                    <h5 style={{ marginBottom: "1em" }}>{item.title}</h5>
                    <p style={{ color: "gray", textAlign: "justify" }}>
                      {item.desc}
                    </p>
                    {item.btn_name ? (
                      <button
                        style={{
                          width: "100%",
                          marginBottom: "1em",
                          background: "rgb(28,63,119)",
                          borderColor: "rgb(28,63,119)",
                        }}
                        onClick={() => navigateToPartner()}
                      >
                        {item.btn_name}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      minHeight: "100px",
                      maxHeight: "340px",
                    }}
                    src={item.img}
                    alt=""
                  />
                </Grid>
              </Grid>
            );
          }
        })}
    </div>
  );
};

export default JoinWithUs;
