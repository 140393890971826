import React, { useState } from "react";
import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { themeSettings } from "../../theme";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

const PageHeader = ({
  title,
  btnText,
  scheduleBtnTxt,
  onAddClick,
  onBtnClick,
  viewList,
  onFilterClick,
  isShowAdd,
  onExportClick,
  wallet,
  myRefferaltnText,
  onMyRefferalClick,
  loading,
  href = true,
  enableForDownload,
  disableFilter,
  backButtonLink,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const typography = themeSettings(theme.palette.mode);
  const [isHovered, setIsHovered] = useState(false);
  const userDataString = localStorage.getItem("user_data");
  const userData = JSON.parse(userDataString);
  const userRole =
    userData?.admin?.user_role?.role || userData?.user?.user_role?.role;

  const location = useLocation();

  const userRoles = [
    "Community Super Admin",
    "Super Admin",
    "Chapter President",
  ];

  const shouldShowTransactionExportButton =
    location?.pathname == "/admin-transactions";

  const shouldShowExportButton =
    userRole.includes("Community Super Admin") &&
    location?.pathname !== "/admin-clientreview" &&
    location?.pathname !== "/admin-banner" &&
    location?.pathname !== "/admin-services" &&
    location?.pathname !== "/admin/business-category" &&
    // location?.pathname !== "/admin-transactions" &&
    location?.pathname !== "/admin-wallet" &&
    location?.pathname !== "/admin-notification" &&
    location?.pathname !== "/admin-privacy-policy" &&
    location?.pathname !== "/admin-faqs-category" &&
    location?.pathname !== "/admin-about-us" &&
    location?.pathname !== "/admin-terms-and-conditions" &&
    location?.pathname !== "/admin-faqs" &&
    location?.pathname !== "/admin-pages" &&
    location?.pathname !== "/admin-page-banners" &&
    location?.pathname !== "/admin-referral" &&
    location?.pathname !== "/admin-my-referrals" &&
    location?.pathname !== "/admin-services/schedule-service-list" &&
    location?.pathname !== "/admin-service-details" &&
    location?.pathname !== "/admin-lead" &&
    location?.pathname !== "/commentlist";

  const shouldShowFilterButton =
    location?.pathname !== "/admin-clientreview" &&
    location?.pathname !== "/admin-banner" &&
    location?.pathname !== "/admin-wallet" &&
    location?.pathname !== "/admin-privacy-policy" &&
    location?.pathname !== "/admin-faqs-category" &&
    location?.pathname !== "/admin-about-us" &&
    location?.pathname !== "/admin-terms-and-conditions" &&
    location?.pathname !== "/admin-pages" &&
    location?.pathname !== "/admin-page-banners" &&
    location?.pathname !== "/admin-service-details";
  // location?.pathname !== "/admin-lead";

  const shouldShowAddButton =
    // userRole === "Community Super Admin" &&
    userRole.includes("Community Super Admin") &&
    location?.pathname === "/admin-withdraw";

  return (
    <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
      <Grid
        container
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          alignItems: "center",
        }}
      >
        <Grid item sm={4} xs={12}>
          <Typography
            fontSize={typography.typography.h4.fontSize}
            sx={{ fontWeight: "bold" }}
          >
            {backButtonLink && (
              <ArrowCircleLeftOutlinedIcon
                fontSize="large"
                sx={{ cursor: "pointer", marginRight: 2 }}
                onClick={() => navigate(backButtonLink)}
              />
            )}
            {title}
          </Typography>
          {userRole !== "Community Super Admin" &&
            location?.pathname === "/admin-withdraw" && (
              <Typography
                fontSize={typography.typography.h4.fontSize}
                sx={{
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Total Amount : {wallet}
              </Typography>
            )}
        </Grid>
        <Grid item sm={8} xs={12}>
          <Box
            sx={{
              display: "flex",
              marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
              justifyContent: "flex-end",
            }}
          >
            <Box display="flex" sx={{ marginTop: "20px" }}>
              {console.log("href_112112112", href)}
              {(shouldShowExportButton ||
                shouldShowTransactionExportButton) && (
                  // <a href={href} download target="_blank" rel="noreferrer">
                  loading ?
                    <Button
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        borderRadius: "50px",
                        border: "0",
                        color: "#fff",
                        "&:hover": {
                          lineHeight: "0px",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "none",
                          color: "#000 !important",
                        },
                        backgroundColor: "#153e80",
                        marginLeft: {
                          xs: "0px",
                          sm: "15px",
                          md: "15px",
                          lg: "15px",
                        },
                        textTransform: "none",
                      }}
                      variant="outlined"
                    >
                      Loading...
                    </Button>
                    :
                    href && href.length > 0 ?
                    <a className="export-link" style={{ color: isHovered ? "#000" : "white", }} href={href} download target="_blank" rel="noreferrer">
                      <Button
                        startIcon={<ArrowDownwardIcon />}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          borderRadius: "50px",
                          border: "0",
                          color: "#fff",
                          "&:hover": {
                            lineHeight: "0px",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "none",
                            color: "#000 !important",
                          },
                          backgroundColor: "#153e80",
                          marginLeft: {
                            xs: "0px",
                            sm: "15px",
                            md: "15px",
                            lg: "15px",
                          },
                          textTransform: "none",
                        }}
                        variant="outlined"
                        onClick={onExportClick}
                        disabled={enableForDownload}
                        // onMouseEnter={() => setIsHovered(true)}
                        // onMouseLeave={() => setIsHovered(false)}
                      >
                        Export
                      </Button>
                    </a>
                    :
                    <Button
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      "&:hover": {
                        lineHeight: "0px",
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "none",
                        color: "#000 !important",
                      },
                      backgroundColor: "#153e80",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    No data found
                  </Button>
                )}

              {myRefferaltnText && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                    },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onMyRefferalClick}
                >
                  {myRefferaltnText}
                </Button>
              )}

              {scheduleBtnTxt && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                    },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={viewList}
                >
                  {scheduleBtnTxt}
                </Button>
              )}

              {btnText && !shouldShowAddButton && (
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                    },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onBtnClick}
                >
                  {btnText}
                </Button>
              )}

              {isShowAdd && (
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                    },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onAddClick}
                >
                  ADD
                </Button>
              )}
              {shouldShowFilterButton && (
                <Button
                  startIcon={<FilterAltIcon />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    "&:hover": {
                      lineHeight: "0px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                    },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    textTransform: "none",
                  }}
                  variant="outlined"
                  onClick={onFilterClick}
                  disabled={disableFilter}
                >
                  Filter
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageHeader;
