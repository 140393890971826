import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReferral,
  getAllReferral,
} from "../../redux/action/ReferralAction.js";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Input from "../../common/Input.js";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../pageHeader/PageHeader.js";
import EnhancedTable from "../table/EnhancedTable.js";
import Loader from "../loader/Loader.js";
import { getBusinessCategory } from "../../redux/action/BusinessCategoryAction.js";
import { getPermissions } from "../../common/permissions.js";
import { permissions } from "../../common/constants.js";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../common/useForms.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons.js";
import DeleteDialog from "../dialog/deletedialog.js";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import { Visibility } from "../../assets/icons/Icons.js";
import useUserRolePermissions from "../../common/UserPermissions.js";
import SelectBox from "../../common/SelectBox.js";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
}));

const initialValues = {
  country: "",
  state: "",
  city: "",
  postalcode: "",
  is_active: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
  // { id: 30, title: "30" },
];

const initialFilterValues = {
  business_category: "",
};

const locationOption = [
  {
    label: "Within Chapter",
    value: 1,
  },
  {
    label: "City",
    value: 2,
  },
  {
    label: "State",
    value: 3,
  },
  {
    label: "Global",
    value: 4,
  },
];

const ReferralList = () => {
  const userRoleData = useUserRolePermissions("Event");
  const { getAllReferralLoading, allReferral } = useSelector(
    (state) => state.ReferralReducer
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [DeleteId, SetDeleteId] = useState(null);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filterDatas, setFilterDatas] = useState(initialFilterValues);
  const [isFieldFilled, setIsFieldFilled] = useState()

  const isAddAllowed = getPermissions(permissions.referral, "is_add");
  const isFilterFormFilled = filterDatas.business_category.trim().length !== 0;

  useEffect(() => {
    dispatch(getAllReferral(page, rowsPerPage, ""));
  }, [page, rowsPerPage]);


  useEffect(() => {
    if (filter) {
      const anyFieldHasValue = Object.values(filterDatas).some(val => val !== "");
      setIsFieldFilled(anyFieldHasValue);
    }
  }, [filter, filterDatas])

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const columns = [
    {
      id: "business_category",
      numeric: false,
      disablePadding: true,
      label: "Business Category",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "closing_date",
      numeric: true,
      disablePadding: false,
      label: "End Date",
    },

    // {
    //   id: "is_active",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Is Active",
    // },
    // (isEditAllowed || isDeleteAllowed) ?
    !!getPermissions(permissions.referral, "is_delete") ||
    !!getPermissions(permissions.referral, "is_edit")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);

  const tableTitle = "ReferralList";

  const handleClickViewReferralDetail = (referral) => {
    nav(`/admin-referral/view-referral`, {
      state: {
        formName: "view_referral_details",
        referralDetailData: referral,
      },
    });
    setErrors({});
  };
  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ? txt : txt.substring(0, len - 1) + "...";
    return acceptedText;
  }
  const rows =
    allReferral?.referralData && Array.isArray(allReferral?.referralData)
      ? allReferral?.referralData.map((referral) => {
          // const selected = service === selectPostal;
          const closingDate = new Date(referral.closing_date);
          const formattedDate = closingDate.toISOString().split("T")[0];
          return {
            _id: referral?._id,
            business_category: referral?.business_category,
            description: subStr(referral?.description,50),
            closing_date: formattedDate,
            action: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!!getPermissions(permissions.referral, "is_edit") && (
                  <IconButton
                    onClick={() => handleClickViewReferralDetail(referral)}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#153e80",
                      },
                      "&:focus": {
                        backgroundColor: "white",
                        color: "#153e80",
                        border: "none",
                      },
                    }}
                  >
                    <Visibility />
                  </IconButton>
                )}
              </div>
            ),
            selected: selected,
          };
        })
      : [];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = rows.map((n) => n.id);
      // setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSingleDelete = async () => {
    handleCloseDelete();
    await dispatch(deleteReferral(DeleteId));
    dispatch(getAllReferral());
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRow = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const closeFilterDrawer = () => {
    setFilter(false);
    setFilterData(!filterData);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const location = filterDatas.location;
    setPage(1);
    dispatch(getAllReferral(page, rowsPerPage, location));
    closeFilterDrawer1();
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if (name === "postalcode" && !numericRegex.test(value)) {
      return;
    }

    setFilterDatas({
      ...filterDatas,
      [name]: value,
    });
  };

  const resetFormFilter = () => {
    setFilterDatas(initialFilterValues);
    setPage(1);
    dispatch(getAllReferral(page, rowsPerPage, ""));
    closeFilterDrawer();
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };

  return (
    <div>
      <Box>
        <PageHeader
          title="Referral Request List"
          isShowAdd={false}
          myRefferaltnText={"My Refferals"}
          onMyRefferalClick={() => nav("/admin-my-referrals")}
          // onAddClick={handleClickOpen}
          onFilterClick={openFilterDrawer}
          // href={downLoadData}
        />
      </Box>
      {getAllReferralLoading ? (
        <Loader />
      ) : (
        <EnhancedTable
          rowsPerPage={rowsPerPage}
          key={5}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={allReferral && allReferral?.referralData}
        />
      )}

      <DeleteDialog
        open={openDelete}
        // loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            Showing {allReferral?.currentPage} of {allReferral?.totalPages}{" "}
            Pages
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={allReferral && allReferral?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmitFilter} className={classes.filterMain}>
            <Box className={classes.mainFilterBox}>
              <Stack className={classes.stackFilter}>
                <Typography className={classes.typographyFilter}>
                  Filter
                </Typography>

                <CloseIcon
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <div>
                <Box>
                  <SelectBox
                    name="location"
                    label="Type"
                    placeholder="Chapter"
                    // disabled={isAnyFieldFilled && !filterDatas.status}
                    ClassName="status-select-filter"
                    value={filterDatas.location}
                    onChange={handleChange}
                    options={locationOption}
                  />
                </Box>
                <Stack className={classes.buttonFilter}>
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="cancel-filter"
                    onClick={resetFormFilter}
                    disabled={!isFieldFilled}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="order-filter-button"
                    type="submit"
                    disabled={!isFieldFilled}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Filter
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Drawer>
      </Box>
    </div>
  );
};

export default ReferralList;
