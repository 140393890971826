import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  Drawer,
  Stack,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "../../components/table/table.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import EnhancedTable from "../../components/table/EnhancedTable.js";
import { DeleteIcon, EditIcon, CloseIcon } from "../../assets/icons/Icons";
import { Form, useForm } from "../../common/useForms";
import FormDialog from "../../components/dialog/dialog";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DeleteDialog from "../dialog/deletedialog";
import { useNavigate } from "react-router-dom";
import {
  CreateClient,
  DeleteClient,
  GetAllClient,
  UpdateClient,
} from "../../redux/action/ClientReviewAction";
import { getPermissions } from "../../common/permissions";
import { permissions } from "../../common/constants";
import Loader from "../loader/Loader";
import useUserRolePermissions from "../../common/UserPermissions";
import SelectBox from "../../common/SelectBox.js";
import { ApiURL, snackBar } from "../../redux/common.js";
import { api } from "../../web/config/api.js";

const useStyles = makeStyles((theme) => ({
  filterMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  mainFilterBox: {
    padding: "10px 20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },

    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
  },

  stackFilter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  typographyFilter: {
    fontWeight: "bold !important",
    color: "#000 !important",
    fontSize: "30px !important",
  },
  buttonFilter: {
    flexDirection: "row !important",
    marginTop: "8px",
    gap: "8px",
  },
  customCircularProgress: {
    width: "25px !important",
    height: "25px !important",
    color: "#fff !important",
  }
}));


const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
];


const initialValues = {
  client_name: "",
  client_profile: "",
  client_image: "",
  review: "",
};

const RecordPerPage = [
  { id: 5, title: "5" },
  { id: 10, title: "10" },
  { id: 15, title: "15" },
  { id: 20, title: "20" },
];

const ClientReviewList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [selectedClient, setSelectedBanner] = useState(null);

  const [DeleteId, SetDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editImageValue, setEditImageValue] = useState(null);
  const role = useUserRolePermissions();
  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const clientData = useSelector(
    (state) => state?.ClientReviewReducer?.client?.reviewData
  );

  const useClasses = useStyles();

  const { loading, client, createLoading, updateLoading, deleteLoading } =
    useSelector((state) => state?.ClientReviewReducer);

    const handleChipClick = (data) => {
      setStatusModal(data);
    };

  useEffect(() => {
    dispatch(GetAllClient(page, rowsPerPage));
  }, [page, dispatch]);

  const handleChangeRow = (event) => {
    const rowsPerPage = event.target.value;
    setRowsPerPage(event.target.value);
    setPage(1);
    dispatch(GetAllClient(page, rowsPerPage));
  };

  // const { banner } =
  //     useSelector((state) => state.BannerReducer);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const tableTitle = "ClientReviewList";

  useEffect(() => {
    if (!open) {
      dispatch(GetAllClient(page, rowsPerPage));
    }
  }, [page, dispatch]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpen = () => {
    setPopupName("Create Client Review");
    setOpen(true);
    setEditImageValue();
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    const hasNumbers = /^[A-Za-z][A-Za-z\s]*$/;
    const allowedTypes = [
      "jpeg",
      "jpg",
      "JPEG",
      "JPG",
      "png",
      "PNG",
      "SVG",
      "svg",
      "webp",
      "WEBP",
      "avi",
      "AVI",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "image/mpeg-2",
    ];
    let isImage = true;
    let fileTypeArray =
      fieldValues.client_image && fieldValues.client_image.split(".");
    let fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    if (fileTypeArray && fileTypeArray.length >= 1) {
      fileType = fileTypeArray && fileTypeArray[fileTypeArray.length - 1];
    }
    if (popupName === "Edit Client Review") {
      if ("client_name" in fieldValues) {
        if (
          fieldValues.client_name === "" ||
          fieldValues.client_name === undefined ||
          fieldValues.client_name === null ||
          fieldValues.client_name?.trim() === ""
        ) {
          temp.client_name = "Please enter client name";
        } else if (!hasNumbers.test(fieldValues.client_name)) {
          temp.client_name = "Client name should not contain numbers";
        } else {
          temp.client_name = "";
        }
      }
      if ("client_image" in fieldValues) {
        if (
          fieldValues.client_image === "" ||
          fieldValues.client_image === undefined ||
          fieldValues.client_image === null ||
          fieldValues.client_image?.trim() === ""
        ) {
          temp.client_image = "This field is required.";
        } else if (fileType && !allowedTypes.includes(fileType)) {
          temp.client_image = "Please select valid image file";
        } else {
          temp.client_image = "";
        }
      }
      if ("client_profile" in fieldValues) {
        if (!hasNumbers.test(fieldValues.client_profile)) {
          temp.client_profile = "Client profile without leading spaces";
        } else {
          temp.client_profile = "";
        }
      }
    } else {
      if ("client_image" in fieldValues) {
        if (
          fieldValues.client_image === "" ||
          fieldValues.client_image === undefined ||
          fieldValues.client_image === null
        ) {
          temp.client_image = "This field is required.";
        } else if (fileType && !allowedTypes.includes(fileType)) {
          temp.client_image = "Please select valid image file";
        } else {
          temp.client_image = "";
        }
      }

      if ("client_name" in fieldValues) {
        if (
          fieldValues.client_name === "" ||
          fieldValues.client_name === undefined ||
          fieldValues.client_name === null
        ) {
          temp.client_name = "Please enter Client name";
        } else if (!hasNumbers.test(fieldValues.client_name)) {
          temp.client_name = "Client name should not contain numbers";
        } else {
          temp.client_name = "";
        }
      }

      if ("client_profile" in fieldValues) {
        if (
          fieldValues.client_profile === "" ||
          fieldValues.client_profile === undefined ||
          fieldValues.client_profile === null
        ) {
          temp.client_profile = "Please enter Client profile";
        } else if (!hasNumbers.test(fieldValues.client_profile)) {
          temp.client_profile = "Client profile without leading spaces";
        } else {
          temp.client_profile = "";
        }
      }

      if ("review" in fieldValues) {
        if (
          fieldValues.review === "" ||
          fieldValues.review === undefined ||
          fieldValues.review === null
        ) {
          temp.review = "Please enter review";
        } else {
          temp.review = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setFieldValues,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleEditClick = async (data) => {
    setSelectedBanner(data);
    setErrors({});
    setPopupName("Edit Client Review");

    if (data?.client_image) {
      setEditImageValue(data?.client_image);
    }

    setValues({
      ...values,
      client_name: data?.client_name || "",
      client_profile: data?.client_profile || "",
      review: data?.review || "",
      client_image: data?.client_image || "",
    });
    setErrors({});
    setOpen(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    SetDeleteId(data._id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleModalClose = () => {
    setStatusModal(false);
  };

  const handleSingleDelete = async () => {
    await dispatch(DeleteClient(DeleteId, handleCloseDelete)).then((res) => {
      if (res && res.data.status === true) {
        dispatch(GetAllClient(page, rowsPerPage));
      }
    });
    if (clientData) {
      setPage(page - 1);
      dispatch(GetAllClient(page, rowsPerPage));
    }
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    setStatusUpdateLoading(true);
    try {
      const statusUpdate = e.target[0].value;
      const payload = {
        approval_status: statusUpdate,
      };
      const res = await api.put(
        `${ApiURL}review/update/${statusModal._id}`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        dispatch(
          GetAllClient(page, rowsPerPage)
        );
        handleModalClose();
      }
    } catch (err) {
      dispatch(
        GetAllClient(page, rowsPerPage)
      );
      handleModalClose();
    } finally {
      setStatusUpdateLoading(false);
      handleModalClose();
    }
  };

  const fields = [
    {
      id: "client_name",
      label: "Client Name",
      type: "text",
      value: values.client_name,
      name: "client_name",
      errors: errors.client_name,
      maxLength: 25,
    },
    {
      id: "client_profile",
      label: "Client Profile",
      type: "text",
      value: values.client_profile,
      name: "client_profile",
      errors: errors.client_profile,
      maxLength: 150,
    },
    {
      id: "review",
      label: "Review",
      type: "textarea",
      value: values.review,
      name: "review",
      errors: errors.review,
      multiline: true,
      rows: 2,
      length: 200,
    },
    {
      id: "client_image",
      type: "file",
      labelAccess: "client_image",
      value: values.client_image,
      name: "client_image",
      errors: errors.client_image,
      multiple: false,
      src: values.file ? values.file : editImageValue,
    },
  ];
  const columns = [
    {
      id: "client_image",
      numeric: true,
      disablePadding: false,
      label: "Client Image",
    },
  
    {
      id: "client_name",
      numeric: false,
      disablePadding: true,
      label: "Client Name",
    },
  
    {
      id: "client_profile",
      numeric: false,
      disablePadding: true,
      label: "Client Profile",
    },
  
    {
      id: "review",
      numeric: false,
      disablePadding: true,
      label: "Review",
    },  
    {
      id: "approval_status",
      numeric: false,
      disablePadding: true,
      label: "Approvel Status",
    },
    
  
    !!getPermissions(permissions.ClientReview, "is_edit") ||
      !!getPermissions(permissions.ClientReview, "is_delete")
      ? { id: "action", numeric: true, disablePadding: false, label: "Action" }
      : null,
  ].filter((el) => el !== null);
  const rows =
    clientData && Array.isArray(clientData)
      ? clientData?.map((data) => {
        // const selected = data === selectedClient;
        return {
          _id: data._id,
          client_image: (
            <a target="_blank" href={data.client_image}>
              <img
                style={{ width: "70px" }}
                src={data.client_image}
                alt="GGC"
              />
            </a>
          ),
          client_name: data.client_name,
          client_profile: (
            <div style={{ width: "200px" }}>
              {data.client_profile &&
                (data.client_profile?.length > 30
                  ? data.client_profile?.substring(0, 30) + "..."
                  : data.client_profile)}
            </div>
          ),
          review: (
            <div style={{ width: "200px" }}>
              {data?.review &&
                (data.review?.length > 30
                  ? data?.review?.substring(0, 30) + "..."
                  : data?.review)}
            </div>
          ),
          approval_status: data.approval_status,
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!!getPermissions(permissions.ClientReview, "is_edit") && (
                <IconButton
                  onClick={() => handleEditClick(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {!!getPermissions(permissions.ClientReview, "is_delete") && (
                <IconButton
                  onClick={() => handleClickOpenDelete(data)}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#153e80",
                    },
                    "&:focus": {
                      backgroundColor: "white",
                      color: "#153e80",
                      border: "none",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ),
          // selected: selected,
        };
      })
      : [];

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append("client_image", values.file ? values.file : editImageValue);
    formData.append("client_name", values.client_name);
    formData.append("client_profile", values.client_profile);
    formData.append("review", values.review);

    if (validate()) {
      if (popupName === "Edit Client Review") {
        dispatch(UpdateClient(selectedClient._id, formData, handleClose))
          .then(() => {
            dispatch(GetAllClient(page, rowsPerPage));
            setSubmitting(false);
          })
          .catch((error) => {
            console.error("Update client dispatch error:", error);
            setSubmitting(false);
          });
      } else {
        dispatch(CreateClient(formData, handleClose))
          .then(() => {
            // Fetch updated client list after successful creation
            dispatch(GetAllClient(page, rowsPerPage));
            setSubmitting(false);
          })
          .catch((error) => {
            console.error("Create client dispatch error:", error);
            setSubmitting(false);
          });
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <PageHeader
        title="Client Review"
        onAddClick={handleClickOpen}
        isShowAdd={!!getPermissions(permissions.ClientReview, "is_add")}
      />

      {loading ? (
        <Loader />
      ) : (
        <EnhancedTable
          key={rows._id}
          rowsPerPage={rowsPerPage}
          columns={columns}
          rows={rows}
          order={order}
          orderBy={orderBy}
          selected={selected}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          title={tableTitle}
          tableList={clientData}
          handleChipClick={ handleChipClick}
        />
      )}
      <FormDialog
        open={open}
        dialogTitle={popupName}
        fields={
          popupName === "Edit Banner"
            ? fields.filter((el, index) =>
              [
                "client_name",
                "client_profile",
                "review",
                "client_image",
              ].includes(el.name)
            )
            : fields.filter((el) => !["status"].includes(el.name))
        }
        loading={popupName === "Edit Client" ? updateLoading : createLoading}
        disableButton={submitting}
        submitText="Submit"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onChange={handleInputChange}
      />

      <DeleteDialog
        open={openDelete}
        loading={deleteLoading}
        dialogTitle="Are you sure to want to delete this record?"
        handleClose={handleCloseDelete}
        handleDelete={() => handleSingleDelete(DeleteId)}
      />

      <Box
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
          marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            gap: 2,
            width: { sm: "100%", md: "50%" },
          }}
          display="flex"
          alignItems="center"
        >
          <Box>
            {" "}
            Showing {client?.currentPage} of {client?.totalPages} Results
          </Box>
          <Box display="flex" sx={{ gap: 1 }} alignItems="center">
            <Typography>Records per page</Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="limites"
                name="limites"
                value={rowsPerPage}
                onChange={handleChangeRow}
                displayEmpty
                sx={{ maxWidth: "65px", maxHeight: "2.2rem" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {RecordPerPage?.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Pagination
          count={client?.totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />
       <Dialog
        open={statusModal}
        fullWidth={false}
        onClose={false}
        disableBackdropClick={true} // Prevent closing when clicking outside
        disableEscapeKeyDown={true}
      >
        <div
          className="mui-dialog-box-popup"
          style={{ backgroundColor: "#fff", color: "#000" }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "#000 !important" }}>
              Update Approval Status
            </Typography>

            <IconButton
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#153e80",
                },
                "&:focus": {
                  backgroundColor: "white",
                  color: "#153e80",
                  border: "none",
                },
              }}
             onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ minWidth: "400px" }}>
            <form onSubmit={handleStatusUpdate}>
              <div style={{ marginTop: "5px" }}>
                <SelectBox
                  type="text"
                  id="approval_status"
                  name="approval_status"
                  label="Approval Status"
                  ClassName="range-select-filter"
                 defaultValue={statusModal?.approval_status || "pending"}
                  options={statusOptions}
                />
              </div>
              <DialogActions sx={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="submit"
                 disabled={statusUpdateLoading ? true : false}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "0",
                    ":hover": { bgcolor: "#1976d2", border: "0" },
                    backgroundColor: "#153e80",
                    marginLeft: {
                      xs: "0px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    color: "#fff",
                    textTransform: "none",
                    minWidth: "100px",
                  }}
                >
                  {statusUpdateLoading ? (
                    <CircularProgress
                      className={useClasses.customCircularProgress}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </div>
      </Dialog>
      </Box>
    </Box>
  );
};

export default ClientReviewList;
