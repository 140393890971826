import React, { useEffect, useState } from "react";
import HomeOurDirectory from "../../components/slides/HomeOurDirectory/HomeOurDirectory";
import HomeArticles from "../../components/slides/HomeArticles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ClientReview from "../../components/clientReview/ClientReview";
import HomeCarousel from "../../components/homeTopSlider/HomeCarousel";
import SearchComponent from "../../components/search/SerachComponent";
import Image1 from "../../assets/image/Be_Partner/Image_11.png";

import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Home3 from "../../assets/image/home/Be_a_member.png";
import Home1 from "../../assets/image/home/Be_a_Partner.png";
import Home2 from "../../assets/image/home/Business.png";
import { useNavigate } from "react-router-dom";
import JoinWithUs from "../../components/joinWithUs/JoinWithUs";
import BePartnerPageNavigation from "../../components/BePartnerPageNavigation/BePartnerPageNavigation";
import PeopleIcon from "@mui/icons-material/People";
import { api } from "../../config/api";
import { ApiURL } from "../../../redux/common";
import axios from "axios";
import Slider from "react-slick";
import Loader from "../../components/loader/Loader";

const joinWithUs = [
  {
    img: Home3,
    title: "Be a Member",
    desc: "At our community business, we understand the importance of providing comprehensive support for entrepreneurs and small business owners like you. We offer a range of services to help you thrive in today's competitive market. By becoming a member of our community, you'll gain access to all essential resources in one convenient place, giving you a significant advantage in achieving your entrepreneurial goals.",
    btn_name: "Be a Member",
  },
  {
    img: Home1,
    title: "Be a Partner",
    desc: "Are you ready to elevate your business to new heights? Join the Global Garner Community, where local meets global, and success knows no bounds. As the proud initiative of Global Garner Sales Service Limited, a trailblazer that has achieved a remarkable 6650 crore valuation in just 7 years, we bring you a unique platform that transcends geographical boundaries and propels your business towards unprecedented growth.",
    btn_name: "Be a Partner",
  },
];

const Home = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const nav = useNavigate();

  const [buttonStates, setButtonStates] = useState({
    monthly: true,
    yearly: false,
  });
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    className: "hero-banner-arrow",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const [chapterAchiever, setChapterAchiever] = useState({});
  const [tendingMember, setTendingMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    setLoading(true)
    const response = await axios.get(
      `${ApiURL}page-banner/get-all-page-banners?page_name=home`
    );
    
    if (response?.data?.data?.bannerList) {
      setLoading(false)
      setBanners(response?.data?.data?.bannerList);
    }else{
      setLoading(false)
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const getchapterAchiever = async () => {
    try {
      const res = await api.get(`${ApiURL}user/get-chapter-achiever-info`);
      setChapterAchiever(res.data);
    } catch (error) {
      // throw error;
    }
  };

  useEffect(() => {
    if (token) {
      getchapterAchiever();
    }
  }, []);

  const getTending = async () => {
    try {
      const queryParams = {
        monthly: buttonStates.monthly,
        yearly: buttonStates.yearly,
      };

      const res = await api.get(`${ApiURL}user/chapter-trending-member`, {
        params: queryParams,
      });

      setTendingMember(res.data);
    } catch (error) {
      throw new Error("Error in tending member api", error.message);
    }
  };

  useEffect(() => {
    if (token) {
      getTending();
    }
  }, [buttonStates]);

  // const handleButtonClick = (buttonName) => {
  //   setButtonStates((prevStates) => ({
  //     ...prevStates,
  //     [buttonName]: !prevStates[buttonName],
  //   }));
  // };

  const handleButtonClick = (type) => {
    if (type === "monthly") {
      setButtonStates({
        monthly: true,
        yearly: false,
      });
    } else if (type === "yearly") {
      setButtonStates({
        monthly: false,
        yearly: true,
      });
    }
  };

  const cardVariants = {
    initial: { opacity: 0, y: -150 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const handleBeaMemeber = () => {
    nav("/be-member");
  };
  const handleBeaPartner = () => {
    nav("/be-partner");
  };

  const token = localStorage.getItem("access_token");

  return (
    <>
      <div className="mt-4 mb-3 home-slider">
        <HomeCarousel />
      </div>
      <div className="content-wrap">
        <section id="section-01">
          <SearchComponent />
          <div className="home-main-how-it-work bg-white pt-5">
            <div className="container">
              <h2 className="mb-5">
                <span>See </span>
                <span className="font-weight-light">How It Works</span>
              </h2>
              <motion.div
                ref={ref}
                variants={containerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
                className="row no-gutters pb-5"
              >
                <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                  <motion.div
                    variants={cardVariants}
                    className="media icon-box-style-02 align-items-center"
                  >
                    <div className="d-flex flex-column mr-4">
                      <span className="number h1 font-weight-bold">1</span>
                    </div>
                    <div className="media-body lh-14">
                      <h5 className="mb-3 lh-1 d-flex align-items-end">
                        <svg
                          className="icon icon-checkmark-circle"
                          style={{ fill: "#00CC00" }}
                        >
                          <use xlinkHref="#icon-checkmark-circle" />
                        </svg>
                        &nbsp; Submission
                      </h5>
                      <p className="font-size-md text-gray mb-0 text-muted text-justify">
                        Verify Your membership eligibility by submitting
                        application
                      </p>
                    </div>
                  </motion.div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                  <motion.div
                    variants={cardVariants}
                    className="media icon-box-style-02 align-items-center"
                  >
                    <div className="d-flex flex-column mr-4 ">
                      <span
                        className="number h1 font-weight-900"
                        style={{ color: "#898989" }}
                      >
                        2
                      </span>
                    </div>
                    <div className="media-body lh-14">
                      <h5 className="mb-3 lh-1 d-flex align-items-end">
                        <svg
                          className="icon icon-checkmark-circle"
                          style={{ fill: "#009900" }}
                        >
                          <use xlinkHref="#icon-checkmark-circle" />
                        </svg>
                        &nbsp; Activation
                      </h5>
                      <p className="font-size-md text-gray mb-0 text-muted text-justify">
                        Activate Your membership and get Dedicated RM appointment
                      </p>
                    </div>
                  </motion.div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 px-0 px-lg-1">
                  <motion.div
                    variants={cardVariants}
                    className="media icon-box-style-02 align-items-center"
                  >
                    <div className="d-flex flex-column mr-4">
                      <span
                        className="number h1 font-weight-1000"
                        style={{ color: "#353535" }}
                      >
                        3
                      </span>
                    </div>
                    <div className="media-body lh-14">
                      <h5 className="mb-3 lh-1 d-flex align-items-end">
                        <svg
                          className="icon icon-checkmark-circle"
                          style={{ fill: "#006600" }}
                        >
                          <use xlinkHref="#icon-checkmark-circle" />
                        </svg>
                        &nbsp; Acceleration
                      </h5>
                      <p className="font-size-md text-gray mb-0 text-muted text-justify">
                        Enjoy your membership offerings with community Networking
                      </p>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
              <div className="border-bottom" />
            </div>
          </div>
          <div className="container mt-4 mb-3 home-slider">
            {loading ?
              <div style={{ display: "flex", justifyContent: "center", alignItem: "center", width: "100%", height: "100vh" }}>
                <Loader />
              </div>
              :
              banners.length ?
              <Slider {...settings}>
                {banners?.map((item, index) => {
                  let isImage = true;
                  let fileType = "";

                  if (typeof item?.page_image === "string") {
                    fileType = item?.page_image.substring(item?.page_image?.lastIndexOf(".") + 1);
                    isImage = fileType.match(/(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i);
                  }
                  return (
                    <div key={index}>
                      <div className="box" style={{ width: "100%", display: "flex", height: "100vh" }}>
                        {isImage ? (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={item?.page_image}
                            alt="Client logo"
                          />
                        ) : (
                          <video width={"100%"} autoPlay loop muted>
                            <source src={item?.page_image} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    </div>
                  );
                }
                )}
              </Slider>
              :
              <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"50vh"}}>
                  <h3>No any page banner images</h3>
              </div>
            }
          </div>
        </section>
        <div className="container pb-3">
          <Grid container>
            {token ? (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: "100%",
                          marginTop: "20px",
                          marginBottom: "20px",
                          // height: "20%",
                        }}
                      >
                        <CardContent>
                          <Typography sx={{ fontSize: 20, fontWeight: "800" }}>
                            My Chapter Achiever's Board
                          </Typography>

                          <Divider />

                          <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 12, sm: 12, md: 12 }}
                              sx={{ justifyContent: "center" }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                sx={{
                                  justifyContent: "center",
                                  display: "flex",
                                  marginTop: { xs: "20px", md: "42px" },
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 20, fontWeight: "600" }}
                                >
                                  Givers
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{ fontSize: "30px", color: "#ff6c6c" }}
                                    />
                                  </Box>
                                  <Box sx={{ marginLeft: "10px" }}>
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_giver_business
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: { xs: "20px" },
                                        }}
                                      >
                                        Business
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{ fontSize: "30px", color: "#ff6c6c" }}
                                    />
                                  </Box>
                                  <Box sx={{ marginLeft: "10px" }}>
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_giver_count
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Referral
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider sx={{ marginTop: "15px" }} />

                          <Box sx={{ flexGrow: 1, marginTop: "15px" }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 12, sm: 12, md: 12 }}
                              sx={{ justifyContent: "center" }}
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                sx={{
                                  justifyContent: "center",
                                  display: "flex",
                                  marginTop: { xs: "20px", md: "42px" },
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 20, fontWeight: "600" }}
                                >
                                  Receivers
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{
                                        fontSize: "30px",
                                        color: "#ff6c6c",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_receiver_business
                                      }
                                      color="secondary"
                                      showZero
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Business
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Card
                                  md={12}
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    backgroundColor: "#e5e5e5",
                                    borderRadius: "15px",
                                    height: "100px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    lineHeight: "40px",
                                    width: "100%", // Adjusted width for responsiveness
                                    marginBottom: "20px", // Added margin for spacing
                                  }}
                                >
                                  <Box>
                                    <PeopleIcon
                                      sx={{
                                        fontSize: "30px",
                                        color: "#ff6c6c",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Badge
                                      badgeContent={
                                        chapterAchiever?.referral_receiver_count
                                      }
                                      showZero
                                      color="secondary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          backgroundColor: "#0b4078",
                                          top: "-7px",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Referral
                                      </Typography>
                                    </Badge>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: "100%",
                          marginTop: "20px",
                          marginBottom: "20px",
                          height: {xs:"100%",sm:"100%",md:"100%",lg:"358px"}
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            spacing={2}
                            mb={1}
                            sx={{ display: "flex" }}
                          >
                            <Grid item xs={12} sm={7} md={7} lg={8}
                              sx={{ display: { xs: "flex" }, justifyContent: { xs: "center", sm: "center", md: "left", lg: "left", xl: "left" } }}>
                              <Typography
                                sx={{
                                  fontSize: { xs: 20, md: 20, lg: 20, xl: 20 },
                                  fontWeight: "800",
                                }}
                              >
                                Trending Member of Chapter
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={5}
                              md={5}
                              lg={4}
                              sx={{ display: "flex", justifyContent: { xs: "center", sm: "center", md: "end" } }}
                            >
                              <ButtonGroup
                                variant="contained"
                                aria-label="Basic button group"
                              >
                                <Button
                                  variant={
                                    buttonStates.monthly
                                      ? "contained"
                                      : "outlined"
                                  }
                                  sx={{
                                    color: buttonStates.monthly
                                      ? "white"
                                      : "default",
                                    backgroundColor: buttonStates.monthly
                                      ? "#ff6c6c"
                                      : "default",
                                    "&:hover": {
                                      backgroundColor: buttonStates.monthly
                                        ? "#ff6c6c"
                                        : "default.light",
                                    },
                                  }}
                                  onClick={() => handleButtonClick("monthly")}
                                >
                                  Monthly
                                </Button>
                                <Button
                                  variant={
                                    buttonStates.yearly ? "contained" : "outlined"
                                  }
                                  sx={{
                                    color: buttonStates.yearly
                                      ? "white"
                                      : "default",
                                    backgroundColor: buttonStates.yearly
                                      ? "#ff6c6c"
                                      : "default",
                                    "&:hover": {
                                      backgroundColor: buttonStates.yearly
                                        ? "#ff6c6c"
                                        : "default.light",
                                    },
                                  }}
                                  onClick={() => handleButtonClick("yearly")}
                                >
                                  Yearly
                                </Button>
                              </ButtonGroup>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Box sx={{ flexGrow: 1, marginTop: "25px", height: "100%" }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                                {tendingMember?.business_giver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Business Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                                {tendingMember?.ref_giver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Referral Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>

                              <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                                {tendingMember?.ref_receiver?.map((item) => {
                                  return (
                                    <Card
                                      sx={{
                                        margin: "10px",
                                        width: { xs: "100%", sm: "100%", md: "158px", lg: "158px", xl: "158px" },
                                        height: "200px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            item?.user_details?.profile_picture
                                          }
                                          sx={{ width: 100, height: 100 }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          marginTop: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",

                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            {item?.user_details?.name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "15px",
                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Business Givers
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Card>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <JoinWithUs
                joinWithUs={joinWithUs}
                navigateToMember={handleBeaMemeber}
                navigateToPartner={handleBeaPartner}
              />
            )}
          </Grid>
          <div className="border-bottom" />
        </div>
        <HomeOurDirectory />
        <BePartnerPageNavigation
          text="Service"
          link="/service"
          buttonTitle="Service"
        />
        <section id="section-04">
          <ClientReview
            cardsPerScreen={2}
            title={
              <h2 className="mb-3">
                <span className="font-weight-semibold">Clients </span>
                <span className="font-weight-light">Review</span>
              </h2>
            }
          />
        </section>
        <HomeArticles />
      </div>
    </>
  );
};

export default Home;
