import React, { useState } from "react";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Button } from "@mui/material";
import ScheduleServiceDialog from "./ScheduleServiceDialog";

function subStr(txt, len) {
  let acceptedText =
    txt.length < len ?
      txt :
      txt.substring(0, len - 1) + '...';
  return acceptedText;
}


const ServiceCard = ({ item, handleModalOpen }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [getServiceId, setGetServiceId] = useState();

  const userData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));

  const token = localStorage.getItem("access_token");

  const handleOpenScheduleServiceModule = (id) => {
    if (token) {
      setOpen(true);
      setGetServiceId(id);
    } else {
      handleModalOpen();
    }
  };

  const [scheduledServiceDetail, setScheduledServiceDetail] = useState()

  const handleOpenScheduleServiceDetailModule = (item) => {
    if (token) {
      setOpen(true);
      setScheduledServiceDetail(item)
    } else {
      handleModalOpen();
    }
  };
  const handleCloseScheduleServiceModule = () => {
    setOpen(false);
  };

  return (
    <>
      <div key={item?._id} className="col-md-6 col-lg-4 mb-6">
        <div className="card border-0">
          <Link to={`/service/${item?._id}`} className="hover-scale">
            <div style={{ display: "flex", justifyContent: "center", width: "365px", height: "300px", overflow: "hidden" }}>
              <img
                src={item?.service_images?.length && item?.service_images[0]}
                alt="product 1"
                style={{
                  width: "auto",
                  height: "auto",
                }}
                className="card-img-top image"
              />
            </div>
          </Link>
          <div className="card-body px-0">
            <div className="d-flex mb-1">
              <div className="col-sm-6 col-md-5 col-lg-5 text-left">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <a className="link-hover-dark-primary" onClick={() => navigate(`/service/${item?._id}`)} style={{ cursor: "pointer" }}>{subStr(item?.service_name, 15)}</a>
                  <span style={{ color: "black" }}>₹ {item.service_price}</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-7 col-lg-7 d-flex justify-content-end">
                <span className="text-black">
                  <a
                    href={`tel:${item?.contact_number}`}
                    target="_blank"
                  ><PhoneOutlinedIcon style={{ color: "red" }} /> Book free
                    consultation</a>
                </span>
              </div>
            </div>
            <div className="d-flex">
              {item &&
                Array.isArray(item.purchased_by) &&
                item.purchased_by.includes(userData?.user?._id) ? (
                <div className="col-sm-6 col-md-5 col-lg-12 text-left">
                  <Button
                    disabled
                    variant="outlined"
                    fullWidth
                    sx={{
                      width: "100%",
                      border: "1px solid !important",
                      fontWeight: "bold !important",
                      color: "gray !important",
                    }}
                  >
                    Activated
                  </Button>
                </div>
              ) : (
                <div className="col-sm-6 col-md-5 col-lg-6 text-left">
                  <Button
                    variant="outlined"
                    sx={{
                      "&:hover": {
                        color: "black !important",
                        backgroundColor: "#c2dcf7 !important",
                      },
                      "&:focus": {
                        color: "black !important",
                        backgroundColor: "#c2dcf7 !important",
                        border: "1px solid #8cbae8 !important",
                      },
                    }}
                    onClick={() => navigate(`/service/${item?._id}`)}
                  >
                    Activate now
                  </Button>
                </div>
              )}
              {item?.service_purchased !== true ?
                <div className="col-sm-6 col-md-7 col-lg-6 d-flex justify-content-end">
                  {item?.service_scheduled && item?.scheduled_at !== null ?
                    <Button
                      variant="outlined"
                      sx={{
                        "&:hover": {
                          color: "black !important",
                          backgroundColor: "#c2dcf7 !important",
                        },
                        "&:focus": {
                          color: "black !important",
                          backgroundColor: "#c2dcf7 !important",
                          border: "1px solid #8cbae8 !important",
                        },
                      }}
                      onClick={() => handleOpenScheduleServiceDetailModule(item)}
                    >
                      Scheduled
                    </Button>
                    :
                    <Button
                      variant="outlined"
                      sx={{
                        "&:hover": {
                          color: "black !important",
                          backgroundColor: "#c2dcf7 !important",
                        },
                        "&:focus": {
                          color: "black !important",
                          backgroundColor: "#c2dcf7 !important",
                          border: "1px solid #8cbae8 !important",
                        },
                        // Define responsive styles
                        fontSize: {
                          xs: '0.75rem', // extra-small screens
                          sm: '0.875rem', // small screens
                          md: '0.7rem', // medium screens
                          lg: '0.80rem', // large screens
                          xl: '0.80rem', // extra-large screens
                        },
                        padding: {
                          xs: '6px 12px', // extra-small screens
                          sm: '0px 16px', // small screens
                          md: '0px 20px', // medium screens
                          lg: '0px 12px', // large screens
                          xl: '0px 28px', // extra-large screens
                        },
                        minWidth: {
                          xs: '100px', // extra-small screens
                          sm: '120px', // small screens
                          md: '140px', // medium screens
                          lg: '160px', // large screens
                          xl: '180px', // extra-large screens
                        },
                      }}
                      onClick={() => handleOpenScheduleServiceModule(item?._id)}
                    >
                      Schedule activation
                    </Button>
                  }
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
      <ScheduleServiceDialog
        isOpen={open}
        closeModel={handleCloseScheduleServiceModule}
        getServiceId={getServiceId}
        scheduledServiceDetail={scheduledServiceDetail}
      />
    </>
  );
};

export default ServiceCard;
