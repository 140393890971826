import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import "./bemember.css";
import { useLocation } from "../../context/LocationContext";
import axios from "axios";
import { base_Url, snackBar } from "../../utils/common";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import "./bemember.css";
import { useNavigate } from "react-router-dom";

let emailValidation =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const numericRegex = /^\d+$/;

const nameRegex = /^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/;

const BeMember = () => {
  const navigate = useNavigate();
  const {
    country,
    getAllCountry,
    getState,
    getRole,
    state,
    role,
    getCity,
    city,
    getPostalCode,
    postal,
    setState,
    setCity,
    setPostal,
    isActiverole,
  } = useLocation();
  const [getRoleId, setGetRoleId] = useState("");
  const [getRoleName, setGetRoleName] = useState("");
  const [isSubmmitting, setIsSubmmitting] = useState(false);
  const [getRefferalCode, setGetRefferalCode] = useState("");
  const [approveToSubmit, setApproveToSubmit] = useState({
    isApprove: false,
    message: ""
  });
  const [IsCreateVerifyCode, setisCreateVerifyCode] = useState(false)
  const [getRefferalName, setGetRefferalName] = useState();
  const [isVerifyCode, setIsVerifyCode] = useState(false);
  const filteredActiveRoles = isActiverole.filter((role) => {
    return (
      role.role !== "Community Admin" && role.role !== "Community Super Admin" && role.role !== "Community Member"
    );
  });

  const CreatePartnerReq = async (data, success) => {
    try {
      const res = await axios.post(
        `${base_Url}partner-request/create-request`,
        data
      );

      if (res?.data?.status === true) {
        if (success) {
          success();
        }
        snackBar(res?.data?.message, res?.data?.status);
      } else {
        snackBar(res?.data?.message, res?.data?.status);
      }
    } catch (err) {
      snackBar(err?.message || "API call failed");
    } finally {
      setIsSubmmitting(false);
    }
  };

  const phoneValidation = (value) => {
    if (!value) {
      return false;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber && phoneNumber.isValid();
  };

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Please enter first name")
      .matches(
        nameRegex,
        "First name can only contain alphabetic characters and remove leading space"
      ),
    last_name: Yup.string()
      .required("Please enter last name")
      .matches(
        nameRegex,
        "Last name can only contain alphabetic characters and remove leading space"
      ),
    email: Yup.string()
      .required("Please enter email")
      .matches(emailValidation, "Please enter a valid email"),
    mobile_number: Yup.string()
      .required("Please enter mobile number")
      .test("is-valid-phone", "Mobile number is not valid", phoneValidation),
    city: Yup.object().required("Please select city"),
    state: Yup.object().required("Please select state"),
    country: Yup.object().required("Please select country"),
    postal: Yup.object().required("Please select postal code"),
    role: Yup.object().required("Please select role"),

    // referral_code: Yup.lazy((value) => {
    //   if (getRoleId === "6538a2ea6ad5de8a89f5ee71") {
    //     return Yup.mixed().notRequired();
    //      }
    //   return Yup.string()
    //     .required("Please enter referral code")
    //     .length(8, "Referral code must be exactly 8 characters")
    //     .matches(/^\d{8}$/, "Referral code must be exactly 8 numeric characters");
    // }),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      role: "",
      city: "",
      state: "",
      country: "",
      postal: "",
      message: "",
      // referral_code: "",
      isActiverole: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmmitting(true);
      const number = values.mobile_number.split(" ");
      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_code: number[0]?.replace("+", ""),
        mobile_number: Number(number[1]?.replace("-", "")),
        role_id: values.role._id,
        message: values.message || "-",
        city_id: values.city._id,
        // referral_code:
        //   getRoleId === "6538a2ea6ad5de8a89f5ee71"
        //     ? Number(values.referral_code) || false
        //     : undefined,
        country_id: values.country._id,
        state_id: values.state._id,
        postalcode_id: values.postal._id,
      };
      if (!body?.referral_code) {
        delete body?.referral_code;
      }
      // if (IsCreateVerifyCode || getRoleId === "6538a2ea6ad5de8a89f5ee71"){
        if(approveToSubmit.isApprove) {
          CreatePartnerReq(body, () => {
            resetForm();
            navigate("/");
          });
        } else {
          CreatePartnerReq(body, () => {
            resetForm();
            navigate("/");
          });
          setIsSubmmitting(false);
        }
      // } else {
      //   setIsSubmmitting(false);
      //   snackBar("Please verify your refferal code")
      // }
    },
  });

  const handleGetRoleId = (value) => {
    if (value) {
      setGetRoleId(value._id);
      setGetRoleName(value?.role);
    }
  };

  const handleVerifyRefferalCode = async () => {
    setisCreateVerifyCode(true)
    let body = {
      referral_code: Number(getRefferalCode),
      role_id: getRoleId,
    };

    if (body.role_id === "") {
      snackBar("Please select role", false);
    } else {
      try {
        await axios
          .post(`${base_Url}user/verify-refferal-code`, body)
          .then((res) => {
            if (res?.data?.status) {
              snackBar(res.data.message, res?.data?.status);
              setGetRefferalName(
                res?.data?.data[0]?.first_name +
                " " +
                res?.data?.data[0]?.last_name
              );
              setApproveToSubmit({ isApprove: true, message:""});
              setIsVerifyCode(true);
              setisCreateVerifyCode(true)
            } else {
              snackBar(res.data.message, res?.data?.status);
              setApproveToSubmit({ isApprove: false, message:"Please verify refferal code"});
              setisCreateVerifyCode(false)
            }
          });
      } catch (error) {
        snackBar(error, false);
        setApproveToSubmit({ isApprove: false, message:""});
      }
    }
  };

  useEffect(() => {
    getAllCountry(true);
    getRole();
  }, []);
  // const number = formik.values.mobile_number.split(" ");

  return (
    <div>
      <Box
        sx={{
          margin: 5,
        }}
      >
        <Grid container spacing={{ xs: 2, md: 2, sm: 2, xl: 5, lg: 5 }}>
          <Grid item md={12} xs={12} sm={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item md={6}>
                <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                  <Typography
                    textAlign="center"
                    fontWeight={500}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                    font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
                  >
                    Registration
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} sm={12}>
                        <Input
                          id="first_name"
                          name="first_name"
                          type="text"
                          maxLength={20}
                          label="First Name"
                          onChange={formik.handleChange}
                          value={formik.values.first_name}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                          helperText={
                            formik.touched.first_name &&
                              formik.errors.first_name ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.first_name}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>

                      <Grid item md={6} xs={12} sm={12}>
                        <Input
                          id="last_name"
                          name="last_name"
                          type="text"
                          label="Last Name"
                          maxLength={20}
                          onChange={formik.handleChange}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                          helperText={
                            formik.touched.last_name &&
                              formik.errors.last_name ? (
                              <div className="err-msg err-msg-font">
                                {formik.errors.last_name}
                              </div>
                            ) : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Input
                      id="email"
                      name="email"
                      label="Email Address"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      InputLabelProps={{ sx: { color: "black" } }}
                      helperText={
                        formik.touched.email && formik.errors.email ? (
                          <div className="err-msg err-msg-font">
                            {formik.errors.email}
                          </div>
                        ) : null
                      }
                      maxLength={150}
                    />

                    <MuiPhoneNumber
                      fullWidth
                      defaultCountry={"in"}
                      size="small"
                      variant="outlined"
                      id="mobile_number"
                      name="mobile_number"
                      label="Mobile Number"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        formik.setFieldValue("mobile_number", value);
                      }}
                      value={formik.values.mobile_number}
                      error={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number
                      }
                      helperText={
                        formik.touched.mobile_number &&
                          formik.errors.mobile_number ? (
                          <div className="err-msg-mobile err-msg-font">
                            {formik.errors.mobile_number}
                          </div>
                        ) : null
                      }
                      InputLabelProps={{ sx: { color: "black" } }}
                      sx={{ marginTop: "10px" }}
                    />
                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="role"
                        name="role"
                        value={formik.values.role || null}
                        // classes={customStyles}
                        onChange={(e, value) => {
                          formik.setFieldValue("role", value);
                          handleGetRoleId(value);
                        }}
                        disabled={isVerifyCode ? true : false}
                        options={filteredActiveRoles || []}
                        autoHighlight
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.role && Boolean(formik.errors.role)
                        }
                        helperText={
                          formik.touched.role && formik.errors.role ? (
                            <div className="err-msg-font">
                              {formik.errors.role}
                            </div>
                          ) : null
                        }
                        getOptionLabel={(option) => option.role}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Role"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.role && Boolean(formik.errors.role)
                            }
                          />
                        )}
                      />
                      {formik.touched.role && formik.errors.role && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.role}
                        </Typography>
                      )}
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="country"
                        name="country"
                        value={formik.values.country || null}
                        onChange={(e, value) => {
                          formik.setFieldValue("country", value);
                          if (value?._id) getState(value?._id, true);
                          setState([]);
                          setCity([]);
                          setPostal([]);
                          formik.setFieldValue("state", "");
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("postal", "");
                        }}
                        options={country?.countryData || []}
                        autoHighlight
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country ? (
                            <div className="err-msg-font">
                              {formik.errors.country}
                            </div>
                          ) : null
                        }
                        getOptionLabel={(option) => option.country_name}
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Country"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.country &&
                              Boolean(formik.errors.country)
                            }
                          />
                        )}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.country}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="state"
                        name="state"
                        value={formik.values.state || null}
                        onChange={(e, value) => {
                          formik.setFieldValue("state", value);
                          if (value?._id) getCity(value?._id, true);
                          setCity([]);
                          setPostal([]);
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("postal", "");
                        }}
                        options={state || []}
                        autoHighlight
                        getOptionLabel={(option) => option.state_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="State"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.state)
                            }
                          />
                        )}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.state}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="city"
                        name="city"
                        value={formik.values.city || null}
                        onChange={(e, value) => {
                          formik.setFieldValue("city", value);
                          if (value?._id) getPostalCode(value?._id, true);
                          setPostal([]);
                          formik.setFieldValue("postal", "");
                        }}
                        options={city || []}
                        autoHighlight
                        getOptionLabel={(option) => option.city_name}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="City"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                          />
                        )}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.city}
                        </Typography>
                      )}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <Autocomplete
                        id="postal"
                        name="Postal code"
                        value={formik.values.postal || null}
                        onChange={(e, value) =>
                          formik.setFieldValue("postal", value)
                        }
                        options={postal || []}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.postal_code?.toString()
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.postal && Boolean(formik.errors.postal)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ color: "#000" }}
                            {...params}
                            label="Postal code"
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "#000" },
                            }}
                            error={
                              formik.touched.postal &&
                              Boolean(formik.errors.postal)
                            }
                          />
                        )}
                      />
                      {formik.touched.postal && formik.errors.postal && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: "12px" }}
                        >
                          {formik.errors.postal}
                        </Typography>
                      )}
                    </div>

                    <Input
                      fullWidth
                      maxRows={4}
                      name="message"
                      value={formik.values.message}
                      label="Message"
                      variant="outlined"
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      // error={
                      //   formik.touched.message && Boolean(formik.errors.message)
                      // }
                      // helperText={
                      //   formik.touched.message && formik.errors.message ? (
                      //     <div>{formik.errors.message}</div>
                      //   ) : null
                      // }
                      maxLength={500}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmmitting}
                    >
                      {isSubmmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BeMember;
