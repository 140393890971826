import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import MuiPhoneNumber from "material-ui-phone-number";
import { GetBusinessCategoryAll } from "../../redux/findserachAction";
import parsePhoneNumberFromString from "libphonenumber-js";
import SelectBox from "../../../common/SelectBox";

const schemaValidation = Yup.object().shape({
  business_name: Yup.string().required("Please enter business name").matches(/^[A-Za-z][A-Za-z\s]*$/, "Please enter a valid business name without numbers and leading space"),
  business_email: Yup.string().required("Please enter business email").matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i, "Please enter valid email"),
  business_owner_firstname: Yup.string().required("Please enter owner first name").matches(/^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/, "Please enter a valid first name"),
  business_owner_lastname: Yup.string().required("Please enter owner last name").matches(/^[a-zA-Z]+([a-zA-Z\s]*[a-zA-Z]+)?$/, "Please enter a valid last name"),
  business_contact: Yup.string().required("Please enter business mobile"),
  establish_year: Yup.string().required("Please enter establish year").matches(/^[0-9]{4}$/, "Please enter valid establish year"),
  business_website: Yup.string().required("Please enter business website").matches(/^(https?|ftp|www):\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])*(?:[a-zA-Z]|[0-9]|[-._~:/?#[\]@!$&'()*+,;=])$/, "Please enter valid url"),
  annual_turnover: Yup.string().required("Please enter annual turnover").matches(/^\d+$/, "Please enter turn over"),
  business_type: Yup.string().required("Please enter business type"),
  business_category_id: Yup.string().required("Please enter business category"),
  pan_number: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid pan Number")
    // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid pan Number")
    .required("Please enter pan number"),
  gst_number: Yup.string()
    .required("Please enter gst number")
    .min(15, "GST number must be exactly 15 characters")
    .max(15, "GST number must be exactly 15 characters")
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter valid GST number"),
});

const Step2 = ({ handleBack, handleNext, activeStep, value }) => {
  // const dispatch = useDispatch();


  // const { getAllChapter } = useSelector((state) => state.ChapterReducer);
  const [allBusinessCategory, setAllBusinessCategory] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImage2, setSelectedImage2] = useState();
  const [IsValidGSTDocument, setisValidGSTDocument] = useState({
    isExist:false,
    msg:""
  })
  const [IsValidPANDocument, setisValidPANDocument] = useState({
    isExist:false,
    msg:""
  })

  const allChapterData = [];
  // getAllChapter &&
  // getAllChapter?.chapterData?.map((el) => {
  //   return { value: el?._id, label: el.chapter_name };
  // });

  // useEffect(() => {
  //   dispatch(getChapterAll());
  // }, []);
  
  const handleImageUpload2 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "pdf",
      "docx",
      "docm",
      "doc",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
    ];
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });
    if (invalidFiles.length > 0) {
      setisValidPANDocument({...IsValidPANDocument, msg:"Please select valid image/document file", isExist:false});
      return;
    } else {
      setisValidPANDocument({...IsValidPANDocument, msg:"", isExist:true});
    }
    setSelectedImage2(file);
  };


  
  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "pdf",
      "docx",
      "docm",
      "doc",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
    ];
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });
    if (invalidFiles.length > 0) {
      setisValidGSTDocument({...IsValidGSTDocument, msg:"Please select valid image/document file", isExist:false});
      return;
    } else {
      setisValidGSTDocument({...IsValidGSTDocument, msg:"", isExist:true});
    }
    setSelectedImage1(file);
  };
  const formik = useFormik({
    initialValues: {
      business_name: value.business_name || "",
      business_email: value.business_email || "",
      business_owner_firstname: value.business_owner_firstname || "",
      business_owner_lastname: value.business_owner_lastname || "",
      business_contact: value.business_contact || "",
      establish_year: value.establish_year || "",
      business_website: value.business_website || "",
      annual_turnover: value.annual_turnover || "",
      business_category_id: value.business_category_id || "",
      // business_category_name: value.business_category_name || "",
      business_type: value.business_type || "",
      pan_number: value.pan_number || "",
      gst_number: value.gst_number || "",
      gst_business_document: selectedImage1 || value.gst_business_document || "",
      pan_business_document: selectedImage2 || value.pan_business_document || ""
    },
    validationSchema: schemaValidation,
    onSubmit: (values, { resetForm }) => {
      const body = {
        business_name: values.business_name,
        business_email: values.business_email,
        business_owner_firstname: values.business_owner_firstname,
        business_owner_lastname: values.business_owner_lastname,
        business_contact: values.business_contact,
        establish_year: values.establish_year,
        business_website: values.business_website,
        annual_turnover: values.annual_turnover,
        business_category_id: values.business_category_id,
        business_type: values.business_type,
        pan_number: values.pan_number,
        gst_number: values.gst_number,
        gst_business_document: selectedImage1 || value.gst_business_document || "",
        pan_business_document: selectedImage2 || value.pan_business_document || "",
      };

      const resetInitForm = () => {
        resetForm();
      };
      //   dispatch(CreateUserManage(formData));
      //   resetForm();
      //   resetInitForm();
      if((IsValidGSTDocument.msg || IsValidPANDocument.msg)){
        // if(!(IsValidGSTDocument.isExist || IsValidPANDocument.isExist)){
          return;
        // }
      }
      handleNext(body);
    },
  });


  const getAllBusinessCategory = async () => {
    const res = await GetBusinessCategoryAll();
    if (res) {
      setAllBusinessCategory(res?.data?.categoryData);
    }
  };

  useEffect(() => {
    getAllBusinessCategory();
  }, []);

  const defaultProps = {
    options: allBusinessCategory.map((item) => ({
      value: item._id,
      label: item?.category_name,
    })),

    getOptionLabel: (option) => option.label,
  };

  
  const handleChanges = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/;

    if ((name === 'annual_turnover' || name === 'business_contact' || name === 'establish_year')  && value && !numericRegex.test(value)) {
      return;
    }
    formik.setFieldValue(name, value);
  };
  return (
    <div>
      <Box
        component={Paper}
        sx={{
          padding: {
            xs: "30px",
            sm: "30px",
            md: "40px",
            lg: "50px",
            xl: "60px",
          },
        }}
        // sx={{ backgroundColor: "green" }}
      >
        <Typography
          textAlign="center"
          fontWeight={500}
          fontSize={28}
          marginBottom={2}
          color="black"
          font-family="Nunito,Inter var,Roboto,Helvetica Neue,Arial,sans-serif"
        >
          Business Details
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_name"
                name="business_name"
                type="text"
                label="Business Name"
                maxLength={50}
                onChange={formik.handleChange}
                value={formik.values.business_name}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_name &&
                  Boolean(formik.errors.business_name)
                }
                helperText={
                  formik.touched.business_name && formik.errors.business_name ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_name}
                    </div>
                  ) : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_email"
                name="business_email"
                type="text"
                label="Business Email"
                maxLength={50}
                onChange={formik.handleChange}
                value={formik.values.business_email}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_email &&
                  Boolean(formik.errors.business_email)
                }
                helperText={
                  formik.touched.business_email && formik.errors.business_email ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_email}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_owner_firstname"
                name="business_owner_firstname"
                type="text"
                label="Business Owner First Name"
                maxLength={20}
                onChange={formik.handleChange}
                value={formik.values.business_owner_firstname}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_owner_firstname &&
                  Boolean(formik.errors.business_owner_firstname)
                }
                helperText={
                  formik.touched.business_owner_firstname && formik.errors.business_owner_firstname ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_owner_firstname}
                    </div>
                  ) : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_owner_lastname"
                name="business_owner_lastname"
                type="text"
                label="Business Owner Last Name"
                maxLength={20}
                onChange={formik.handleChange}
                value={formik.values.business_owner_lastname}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_owner_lastname &&
                  Boolean(formik.errors.business_owner_lastname)
                }
                helperText={
                  formik.touched.business_owner_lastname && formik.errors.business_owner_lastname ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_owner_lastname}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <div style={{ marginTop: "5px" }}>
              <Input
                id="business_contact"
                name="business_contact"
                type="text"
                label="Business Contact"
                maxLength={10}
                onChange={handleChanges}
                value={formik.values.business_contact}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_contact &&
                  Boolean(formik.errors.business_contact)
                }
                helperText={
                  formik.touched.business_contact && formik.errors.business_contact ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_contact}
                    </div>
                  ) : null
                }
              />
              </div>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="establish_year"
                name="establish_year"
                type="text"
                label="Establish Year"
                maxLength={4}
                onChange={handleChanges}
                value={formik.values.establish_year}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.establish_year &&
                  Boolean(formik.errors.establish_year)
                }
                helperText={
                  formik.touched.establish_year && formik.errors.establish_year ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.establish_year}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_website"
                name="business_website"
                type="text"
                label="Business Website"
                maxLength={50}
                onChange={formik.handleChange}
                value={formik.values.business_website}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_website &&
                  Boolean(formik.errors.business_website)
                }
                helperText={
                  formik.touched.business_website && formik.errors.business_website ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_website}
                    </div>
                  ) : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="annual_turnover"
                name="annual_turnover"
                type="text"
                label="Annual Turnover"
                maxLength={10}
                onChange={handleChanges}
                value={formik.values.annual_turnover}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.annual_turnover &&
                  Boolean(formik.errors.annual_turnover)
                }
                helperText={
                  formik.touched.annual_turnover && formik.errors.annual_turnover ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.annual_turnover}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="business_type"
                name="business_type"
                type="text"
                label="Business Type"
                maxLength={30}
                onChange={formik.handleChange}
                value={formik.values.business_type}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_type &&
                  Boolean(formik.errors.business_type)
                }
                helperText={
                  formik.touched.business_type && formik.errors.business_type ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.business_type}
                    </div>
                  ) : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
            <SelectBox
                mt={1}
                id="business_category_id"
                name="business_category_id"
                label="Business Category"
                ClassName="range-select-filter"
                value={formik?.values?.business_category_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={allBusinessCategory || []}
                error={
                  formik.touched.business_category_id &&
                  Boolean(formik.errors.business_category_id)
                }
                touched={formik.touched.business_category_id}
              />
              {formik.touched.business_category_id && formik.errors.business_category_id && (
                <div className="err-msg-font">
                  {formik.errors.business_category_id}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="pan_number"
                name="pan_number"
                type="text"
                maxLength={10}
                label="Pan Number"
                onChange={(e) =>
                  formik.setFieldValue(
                    "pan_number",
                    e.target.value.toUpperCase()
                  )
                }
                value={formik.values.pan_number}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pan_number && Boolean(formik.errors.pan_number)
                }
                helperText={
                  formik.touched.pan_number && formik.errors.pan_number ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.pan_number}
                    </div>
                  ) : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <Input
                id="gst_number"
                name="gst_number"
                type="text"
                label="GST Number"
                maxLength={15}
                onChange={(e) =>
                  formik.setFieldValue(
                    "gst_number",
                    e.target.value.toUpperCase()
                  )
                }
                value={formik.values.gst_number}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.gst_number && Boolean(formik.errors.gst_number)
                }
                helperText={
                  formik.touched.gst_number && formik.errors.gst_number ? (
                    <div className="err-msg err-msg-font">
                      {formik.errors.gst_number}
                    </div>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
                      <Grid item md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"space-between"}}>
                        <label>PAN Document</label>
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          id="image-upload2"
                          style={{ display: "none" }}
                          onChange={handleImageUpload2}
                        />
                        <label htmlFor="image-upload2">
                          <Button
                            component="span"
                            variant="contained"
                            color="inherit"
                            style={{ marginLeft: "16px" }}
                          >
                            Upload Pan
                          </Button>
                        </label>
                        {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        {IsValidPANDocument.msg ? (
                          <div className="err-msg-font mt-2 mb-2">
                             {IsValidPANDocument.msg}
                          </div>)
                        :
                        (IsValidPANDocument.isExist || formik?.values && formik?.values.pan_business_document?.name) ? 
                        
                          <div className="mt-2 mb-2" style={{
                            overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                            wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                            whiteSpace: "normal"
                          }}>
                            <span>
                            {selectedImage2 ? selectedImage2.name : formik.values.pan_business_document.name}
                                
                              </span>
                          </div> 
                          : selectedImage2 && (
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              Selected Document:
                              <br />
                              <a target="_blank" href={selectedImage2.name}>
                                {selectedImage2.name || selectedImage2}
                              </a>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
          <Grid item xs={6} md={6}>
                      <Grid item md={12} xs={12} sm={12} sx={{display:"flex",justifyContent:"space-between"}}>
                        <label>GST Document</label>
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          id="image-upload1"
                          style={{ display: "none" }}
                          onChange={handleImageUpload1}
                        />
                        <label htmlFor="image-upload1">
                          <Button
                            component="span"
                            variant="contained"
                            color="inherit"
                          >
                            Upload GST
                          </Button>
                        </label>
                        {/* {selectedImage1 ? null : (
                <div className="err-msg-font">
                  Please select an image to upload.
                </div>
              )} */}
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        {IsValidGSTDocument.msg ? (
                          <div className="err-msg-font mt-2 mb-2">
                             {IsValidGSTDocument.msg}
                          </div>)
                        :
                        (IsValidGSTDocument.isExist || formik?.values && formik?.values.gst_business_document?.name) ? 
                        
                          <div className="mt-2 mb-2" style={{
                            overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                            wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                            whiteSpace: "normal"
                          }}>
                            <span>
                                {selectedImage1 ? selectedImage1.name : formik.values.gst_business_document.name}
                              </span>
                          </div> 
                          :selectedImage1 && (
                          <Grid item md={12} xs={12} sm={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ marginTop: "1rem" }}
                            >
                              Selected Document:
                              <br />
                              <a target="_blank" href={selectedImage1.name || selectedImage1}>
                                {/* {selectedImage1.name || selectedImage1} */}
                                View GST
                              </a>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
          
                    </Grid>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
          >
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="button"
                variant="contained"
                // disabled={activeStep === 0}
                onClick={() => handleBack(formik.values)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ margin: 1 }}
                type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                {!activeStep <= 1 ? "Next" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default Step2;
