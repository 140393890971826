import React from "react";
import CollectionsIcon from "@mui/icons-material/Collections";

const ShowFirstImage = ({ images, height, width, link, ...other }) => {
  function isImage(url) {
    return /\.(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)$/i.test(
      url
    );
  }
  function isVideo(url) {
    return /\.(AVI|AVIF|AVCHD|FLV|F4V|SWF|MPEG-2|MP4|MOV|WMV|MKV|WEBM|avi|avif|avchd|flv|f4v|swf|mpeg-2|mp4|mov|wmv|mkv|webm)$/i.test(url);
  }

  const imageUrls = images?.filter((url) => isImage(url));
  const videoUrls = images?.filter((url) => isVideo(url));
  const fileType = images[0]?.substring(images[0]?.lastIndexOf(".") + 1);

  if (imageUrls?.length > 0) {
    return (
      <a target="" href={link}>
        <img
          style={{
            width: width || "70px",
            height: height || "50px",
            objectFit: "cover",
            ...other
          }}
          src={imageUrls[0]}
          alt="GGC"
        />
      </a>
    );
  } else if (videoUrls?.length > 0) {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={videoUrls[0]} type={`video/${fileType}`} />
        Your browser does not support the video tag.
      </video>
    )
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: width || "70px",
          height: height || "50px",
        }}
      >
        <CollectionsIcon sx={{ fontSize: height }} />
      </div>
    );
  }
};

export default ShowFirstImage;
