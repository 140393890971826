import React, { useEffect, useState } from "react";
import hotel5 from "../../assets/image/shop/hotel-5.jpg";
import hotel4 from "../../assets/image/shop/hotel-4.jpg";
import hotel2 from "../../assets/image/shop/hotel-2.jpg";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../config/api";
import NotFound from "../notFound/NotFound";
import Loader from "../../components/loader/Loader";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Login from "../auth/Login";
import { DisplayHTMLString } from "../../components/htmlToString/HtmlToString";
import PaymentGateway from "../../components/PaymentGateway";
import GuestDetailsModal from "../../components/guestDetailsModal/GuestDetailsModal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { base_Url, snackBar } from "../../utils/common";
import RegistrationLoader from "../../components/loader/RegisterLoader";
import ComCard from "../../../common/ComCard";
import GpsFixedTwoToneIcon from "@mui/icons-material/GpsFixedTwoTone";
import SelectBox from "../../../common/SelectBox";
import { Container } from "@material-ui/core";
import { compareDates } from "../../../utils/dateUtility";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "2px",
  },
  "& .MuiDialogActions-root": {
    padding: "2px",
  },
}));

const guestNumber = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const EventDetails = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const nav = useNavigate()
  const [successModal, setSuccessModal] = useState({
    data: {},
    success: false,
    open: false,
  });

  const handleGuestModalOpen = () => {
    setIsGuestModalOpen(true);
  };
  const handleGuestModalClose = () => {
    setIsGuestModalOpen(false);
  };

  const handleModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  const [formData, setFormData] = useState({
    persons: "" || "1",
    guest: "" || "0",
    type: "" || "1",
    date: "" || "",
  });
  const token = localStorage.getItem("access_token");

  const {
    event_name,
    event_description,
    chapter_name,
    start_time,
    schedule_status,
    end_time,
    price,
    event_images,
    qr_code,
    venue_link,
    venue,
    note,
  } = data;

  const userDataString = JSON.parse(localStorage.getItem("user_data"));

  const [isEventBooked, setIsEventBooked] = useState()

  console.log("userDataString?.user?._id", userDataString?.user?._id)

  useEffect(() => {
    const checkEventBooked = data?.booked_users?.filter((check) => {
      return check?.user_id == userDataString?.user?._id
    })

    setIsEventBooked(checkEventBooked)

  }, [data, token])

  const basePricePerPerson = price;

  const calculateTotalPrice = (data) => {
    const totalPersons = parseInt(data.persons, 10) + parseInt(data.guest, 10);
    return basePricePerPerson * totalPersons;
  };



  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice(formData));
  const [guestDetails, setGuestDetails] = useState([]);
  const [eventRegistrationLoading, setEventRegistrationLoading] =
    useState(false);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(formData));
  }, [formData]);

  const handleInputChange = (event) => {
    // const { id, value } = event.target;
    const { name, value } = event.target;
    if (value <= 10) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGuestBlur = (event) => {
    // const { id, value } = event.target;
    const { name, value } = event.target;
    if (value <= 10) {
      if (name == "guest") {
        setGuestDetails(
          Array.from({ length: value }).fill({
            name: "",
            email: "",
            mobile_number: "",
          })
        );
      }
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!token) {
      handleModalOpen();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { id } = useParams();

  const getEventById = () => {
    api
      .get(`event/event-by-id/${id}`)
      .then((res) => {
        if (res.status) {
          const data = res.data;
          if (Array.isArray(data) && data.length) {
            setData(res.data[0]);
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };


  const [startPaymentProcess, setStartPaymentProcess] = useState(false)


  const handlePaymentSuccess = async (response) => {
    setStartPaymentProcess(true)
    try {
      setEventRegistrationLoading(true);
      const payload = {
        event_id: id,
        razorpay_payment_id: response?.razorpay_payment_id,
        guest_users: guestDetails,
      };
      const res = await api.post(
        `${base_Url}event/event-registration`,
        payload
      );
      if (res?.status) {
        snackBar(res?.message, res?.status);
        setGuestDetails([]);
        setIsGuestModalOpen(false);
        setSuccessModal({
          success: true,
          open: true,
          data: {
            event_id: id,
            razorpay_payment_id: response?.razorpay_payment_id,
          },
        });
        setStartPaymentProcess(false)
        nav("/")
      } else {
        setSuccessModal({
          success: false,
          open: true,
          data: {
            event_id: id,
            razorpay_payment_id: response?.razorpay_payment_id,
          },
        });
        snackBar(res?.message, res?.status);
      }
    } catch {
      setSuccessModal({
        success: false,
        open: true,
        data: {
          event_id: id,
          razorpay_payment_id: response?.razorpay_payment_id,
        },
      });
      snackBar("Please try again!", false);
    } finally {
      setEventRegistrationLoading(false);
    }
  };

  const handlePaymentModalClose = () => {
    setSuccessModal({ success: false, open: false, data: {} });
  };

  useEffect(() => {
    getEventById();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
    centerMode: true,
    centerPadding: "0%",
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotFound />;
  }

  function subStr(txt, len) {
    let acceptedText =
      txt.length < len ?
        txt :
        txt.substring(0, len - 1) + '...';
    return acceptedText;
  }

  const handleStartPaymentProcess = () => {
    setStartPaymentProcess(true)
  }
  const handleClosePaymentProcess = () => {
    setStartPaymentProcess(false)
  }

  return (
    <>
      <div
        id="wrapper-content"
        className="wrapper-content pb-0 pt-0 pl-0 bg-gray-06"
      >
        {eventRegistrationLoading && <RegistrationLoader />}
        <div className="page-title bg-gray-06 pt-1 pb-1 justify-content-center">
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                "@media screen and (min-width: 768px)": {
                  margin: "3rem",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  md={7}
                  xs={12}
                  sm={12}
                  style={{
                    position: "relative",
                    // left: "70px",
                    marginTop: "21px",
                  }}
                >
                  <Grid item md={12} xs={12} sm={12}>
                    <Grid md={8} xs={12} sm={12}>
                      <div className="d-flex flex-wrap mb-3">
                        <h2 className="text-dark mr-3 mb-0" style={{
                          display: "flex",
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}>{event_name}</h2>
                        <span className="check font-weight-semibold text-green my-auto">
                          <svg className="icon icon-check-circle">
                            <use xlinkHref="#icon-check-circle" />
                          </svg>
                          Verified
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={9} xs={12} sm={12} sx={{ display: "flex" }}>
                    <Grid md={6} xs={12} sm={12} sx={{ marginRight: "10px" }}>
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "15px",
                          height: "113px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          lineHeight: "35px",
                        }}
                      >
                        <span className="mr-1">
                          <i
                            className="fal fa-clock"
                            style={{
                              fontSize: "30px",
                              color: "#ff9999",
                            }}
                          />
                        </span>
                        <span>Start : {moment(start_time).format("DD-MM-YYYY, h:mm:ss a")}</span>
                        <span>End : {moment(end_time).format("DD-MM-YYYY, h:mm:ss a")}</span>
                      </Grid>
                    </Grid>
                    <Grid md={6} xs={12} sm={12}>
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "15px",
                          height: "113px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          lineHeight: "40px",
                        }}
                      >
                        <span className="mr-1" style={{ marginTop: "-10px" }}>
                          <LocationOnIcon
                            sx={{
                              fontSize: "30px",
                              color: "#ff6c6c",
                            }}
                          />
                        </span>
                        <span style={{
                          padding: "0px 10px 0px 10px",
                          textAlign: "center",
                          fontSize: "12px",
                          lineHeight: "20px"
                        }}>{subStr(venue, 40)}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} sm={12}>
                    <Grid
                      md={9}
                      xs={12}
                      sm={12}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        height: "66px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="mr-1">
                        <GpsFixedTwoToneIcon
                          sx={{
                            fontSize: "30px",
                            color: "#ff6c6c",
                          }}
                        />
                      </span>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          color: "#ff6c6c",
                          textDecoration: "underline",
                        }}
                        href={(!venue_link?.startsWith("https://") && !venue_link?.startsWith("http://")) ? "https://" + venue_link : venue_link}
                      >
                        Get Direction Now
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                {token ?
                  end_time &&
                    compareDates(new Date(end_time).toUTCString(), new Date().toUTCString()) ? (
                    isEventBooked?.length ? <>
                      <Grid item md={5} xs={12} sm={12}>
                        <Grid
                          md={10}
                          sm={12}
                        >
                          <Box padding={{ xs: 2, sm: 3 }}>
                            <button
                              disabled
                              className="btn btn-primary btn-block"
                              type="button"
                            >
                              Booked
                            </button>
                          </Box>
                        </Grid>
                      </Grid>
                    </> :
                      <Grid item md={5} xs={12} sm={12}>
                        <Grid
                          md={10}
                          sm={12}
                          sx={{
                            backgroundColor: "white !important",
                            borderRadius: "31px",
                          }}
                        >
                          <Box
                            component={Paper}
                            padding={{ xs: 2, sm: 3 }}
                            sx={{
                              borderRadius: "30px",
                              "&.css-11a8l5m-MuiPaper-root": {
                                boxShadow:
                                  "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important",
                              },
                            }}
                          >
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                  <Typography
                                    fontSize={17}
                                    marginBottom={2}
                                    color="black"
                                  >
                                    Persons:
                                  </Typography>
                                  <TextField
                                    className="borderRadius"
                                    sx={{
                                      width: "100%",
                                      ".css-wtvcxf-MuiInputBase-root-MuiOutlinedInput-root":
                                      {
                                        borderRadius: "13px",
                                      },
                                    }}
                                    variant="outlined"
                                    disabled={true}
                                    type="number"
                                    id="persons"
                                    name="persons"
                                    min="1"
                                    value={formData.persons}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                  <Typography
                                    marginTop={2}
                                    marginBottom={1}
                                    fontSize={17}
                                    color="black"
                                  >
                                    Guest:
                                  </Typography>
                                  <SelectBox
                                    type="text"
                                    id="guest"
                                    name="guest"
                                    label="Guest"
                                    ClassName="range-select-filter"
                                    onChange={handleInputChange}
                                    onBlur={handleGuestBlur}
                                    value={formData.guest}
                                    // defaultValue={statusModal?.status || "pending"}
                                    options={guestNumber}
                                  />
                                  <div
                                    className="text-danger font-weight-semibold mt-2 mb-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <span>
                                      Total Amount:₹
                                      {isNaN(totalPrice)
                                        ? basePricePerPerson
                                        : totalPrice}
                                    </span>
                                  </div>
                                  {token ? (
                                    formData.guest > 0 ? (
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        onClick={handleGuestModalOpen}
                                        disabled={formData.guest > 100}
                                      >
                                        Book Now
                                      </button>
                                    ) : (
                                      <PaymentGateway
                                        amount={
                                          isNaN(totalPrice)
                                            ? basePricePerPerson
                                            : totalPrice
                                        }
                                        afterPayment={handlePaymentSuccess}
                                        enablePayementProcess={handleClosePaymentProcess}
                                      >
                                        <button
                                          className="btn btn-primary btn-block"
                                          type="button"
                                          onClick={handleStartPaymentProcess}
                                          disabled={startPaymentProcess}
                                        >
                                          Book Now
                                        </button>
                                      </PaymentGateway>
                                    )
                                  ) : (
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={handleModalOpen}
                                    >
                                      Book Now
                                    </button>
                                  )}
                                </Grid>
                              </Grid>
                            </form>
                          </Box>
                        </Grid>
                      </Grid>
                  ) : (
                    schedule_status !== "cancelled" ?
                      <>
                        <Grid item md={5} xs={12} sm={12}>
                          <Grid
                            md={10}
                            sm={12}
                          >
                            <Box padding={{ xs: 2, sm: 3 }}>
                              <button
                                disabled
                                className="btn btn-primary btn-block"
                                type="button"
                              >
                                Booking Closed
                              </button>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid item md={5} xs={12} sm={12}>
                          <Grid
                            md={10}
                            sm={12}
                          >
                            <Box padding={{ xs: 2, sm: 3 }}>
                              <button
                                disabled
                                className="btn btn-primary btn-block"
                                type="button"
                              >
                                Event cancelled
                              </button>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                  )
                  :
                  end_time &&
                    compareDates(new Date(end_time).toUTCString(), new Date().toUTCString()) ?
                    <Grid item md={5} xs={12} sm={12}>
                      <Grid
                        md={10}
                        sm={12}
                        sx={{
                          backgroundColor: "white !important",
                          borderRadius: "31px",
                        }}
                      >
                        <Box
                          component={Paper}
                          padding={{ xs: 2, sm: 3 }}
                          sx={{
                            borderRadius: "30px",
                            "&.css-11a8l5m-MuiPaper-root": {
                              boxShadow:
                                "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important",
                            },
                          }}
                        >
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12} sm={12}>
                                <Typography
                                  fontSize={17}
                                  marginBottom={2}
                                  color="black"
                                >
                                  Persons:
                                </Typography>
                                <TextField
                                  className="borderRadius"
                                  sx={{
                                    width: "100%",
                                    ".css-wtvcxf-MuiInputBase-root-MuiOutlinedInput-root":
                                    {
                                      borderRadius: "13px",
                                    },
                                  }}
                                  variant="outlined"
                                  disabled={true}
                                  type="number"
                                  id="persons"
                                  name="persons"
                                  min="1"
                                  value={formData.persons}
                                  onChange={handleInputChange}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item md={12} xs={12} sm={12}>
                                <Typography
                                  marginTop={2}
                                  marginBottom={1}
                                  fontSize={17}
                                  color="black"
                                >
                                  Guest:
                                </Typography>
                                <SelectBox
                                  type="text"
                                  id="guest"
                                  name="guest"
                                  label="Guest"
                                  ClassName="range-select-filter"
                                  onChange={handleInputChange}
                                  onBlur={handleGuestBlur}
                                  value={formData.guest}
                                  // defaultValue={statusModal?.status || "pending"}
                                  options={guestNumber}
                                />
                                <div
                                  className="text-danger font-weight-semibold mt-2 mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <span>
                                    Total Amount:₹
                                    {isNaN(totalPrice)
                                      ? basePricePerPerson
                                      : totalPrice}
                                  </span>
                                </div>
                                {token ? (
                                  formData.guest > 0 ? (
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={handleGuestModalOpen}
                                      disabled={formData.guest > 100}
                                    >
                                      Book Now
                                    </button>
                                  ) : (
                                    <PaymentGateway
                                      amount={
                                        isNaN(totalPrice)
                                          ? basePricePerPerson
                                          : totalPrice
                                      }
                                      afterPayment={handlePaymentSuccess}
                                      enablePayementProcess={handleClosePaymentProcess}
                                    >
                                      <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        onClick={handleStartPaymentProcess}
                                        disabled={startPaymentProcess}
                                      >
                                        Book Now
                                      </button>
                                    </PaymentGateway>
                                  )
                                ) : (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="button"
                                    onClick={handleModalOpen}
                                  >
                                    Book Now
                                  </button>
                                )}
                              </Grid>
                            </Grid>
                          </form>
                        </Box>
                      </Grid>
                    </Grid>
                    :
                    <Grid item md={5} xs={12} sm={12}>
                      <Grid
                        md={10}
                        sm={12}
                      >
                        <Box padding={{ xs: 2, sm: 3 }}>
                          <button
                            disabled
                            className="btn btn-primary btn-block"
                            type="button"
                          >
                            Event closed
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </Box>
          </Container>
        </div>
      </div>
      <div id="wrapper-content" className="wrapper-content pb-0 pt-0">
        <div className="container">
          {/* Grid */}
          <Grid container spacing={1}>
            <Grid item md={6} xs={12} sm={12}>
              <Slider {...settings} className="mt-2">
                {event_images.map((link, index) => {
                  // Check media type based on the link (assuming link includes file extensions)
                  const fileType = link.substring(link.lastIndexOf(".") + 1);
                  const isImage = fileType.match(
                    /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
                  );

                  // Render image or video based on media type
                  return (
                    <div key={index}>
                      <div
                        className="box center"
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        {isImage ? (
                          <img
                            style={{
                              minHeight: "40%",
                              maxHeight: "40%",
                              width: "100%",
                            }}
                            // height: "568.188px"
                            src={link}
                            alt={`Image ${index}`}
                          />
                        ) : (
                          <video
                            style={{
                              minHeight: "40%",
                              maxHeight: "40%",
                              width: "100%",
                            }}
                            autoPlay
                            loop
                            muted
                            playsInline
                          >
                            <source src={link} type={`video/${fileType}`} />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </Grid>
            {/* Grid */}
            <Grid item md={6} xs={12} sm={12}>
              <div className="page-container ml-3">
                <div className="row">
                  <div className={`page-content col-xl-12 mb-8 mb-xl-0 mt-3`}>
                    <div className="explore-details-container">
                      <div className="mb-9">
                        <h3 className="font-size-lg text-uppercase font-weight-semibold border-bottom pb-1 pt-2 mb-6">
                          description
                        </h3>
                        <div className="mb-7">
                          <p className="mb-6" style={{
                            overflowWrap: "break-word", /* Modern equivalent of word-wrap */
                            wordBreak: "break-word", /* Breaks words at arbitrary points if necessary */
                            whiteSpace: "normal"
                          }}>
                            {DisplayHTMLString(event_description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {end_time && compareDates(new Date(end_time).toUTCString(), new Date().toUTCString()) ? (
          <></>
        ) : (
          <div className="container">
            <div className="page-container">
              <div className="row">
                <div className={`page-content col-xl-12 mb-8 mb-xl-0`}>
                  <div className="explore-details-container">
                    <div className="mb-9">
                      <h3 className="font-size-lg text-uppercase font-weight-semibold border-bottom pb-1 pt-2 mb-6">
                        notes
                      </h3>
                      <div className="mb-7">
                        <p className="mb-6">{DisplayHTMLString(note || "")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {end_time && compareDates(new Date(end_time).toUTCString(), new Date().toUTCString()) ? (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              QR Code
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Stack direction="row" spacing={2}>
                <img
                  style={{ maxWidth: "400px", width: "100%" }}
                  src={qr_code}
                  alt=""
                />
              </Stack>
            </DialogContent>
          </BootstrapDialog>
        ) : (
          <></>
        )}
        <Login open={loginModalOpen} handleClose={handleModalClose} />
        <GuestDetailsModal
          guest={formData.guest}
          open={isGuestModalOpen}
          handleClose={handleGuestModalClose}
          guestDetails={guestDetails}
          setGuestDetails={setGuestDetails}
          checkoutButton={
            <PaymentGateway
              amount={isNaN(totalPrice) ? basePricePerPerson : totalPrice}
              afterPayment={handlePaymentSuccess}
              enablePayementProcess={handleClosePaymentProcess}
            >
              <Button variant="contained" type="button">
                Check Out
              </Button>
            </PaymentGateway>
          }
        />

        <ComCard
          success={successModal?.success}
          open={successModal?.open}
          // success={true}
          // open={true}
          handleClose={handlePaymentModalClose}
        >
          <p>
            <b>Event Id: </b>
            {successModal?.data?.event_id}
          </p>
          <p>
            <b>Razorpay Payment Id: </b>
            {successModal?.data?.razorpay_payment_id}
          </p>
        </ComCard>
      </div>
    </>
  );
};

export default EventDetails;
