import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { compareDates } from "../../../utils/dateUtility";
import ShowFirstImage from "../../../components/showFirstImage/ShowFirstImage";

const EventCard = ({ item }) => {


  return (
    <>
      {item.is_active && item?.approval_status !== 'rejected' && item?.approval_status !== 'pending' && item?.schedule_status !== 'cancelled' ?
        <div key={item._id} className="col-md-6 col-lg-4 mb-6">
          <div className="card border-0">
            <Link to={`/event/${item._id}`} className="hover-scale">
              <div style={{ display: "flex", justifyContent: "center", width: "365px", height: "300px", overflow: "hidden" }}>
                <ShowFirstImage
                  width="auto"
                  height="auto"
                  padding="4px 24px 0px 10px"
                  images={item?.event_images?.length && item.event_images}
                />
              </div>
            </Link>
            <div className="card-body px-0">
              <div className="mb-2">
                <a className="link-hover-dark-primary">
                  {item?.event_type}, {item?.chapter_name}
                </a>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
                <h5 className="card-title lh-13 letter-spacing-25">
                  <Link
                    to={`/event/${item._id}`}
                    className="link-hover-dark-primary text-capitalize"
                  >
                    {item.event_name}
                  </Link>
                </h5>
                <h5 className="card-title lh-13 letter-spacing-25">
                  ₹ {item.price}
                </h5>
              </div>
              <ul className="list-inline">
                <li className="list-inline-item mr-0">
                  <span className="text-gray">
                    {moment(item.start_time).format("MMMM Do YYYY")}
                  </span>
                </li>
                <li className="list-inline-item ml-3">
                  {item?.end_time &&
                    compareDates(new Date(item?.end_time).toUTCString(), new Date().toUTCString()) ? (
                    item?.event_registered ?
                      <>
                        <Box padding={{ xs: 0, sm: 0 }}>
                          <button
                            disabled
                            className="btn btn-primary btn-block"
                            type="button"
                          >
                            Booked
                          </button>
                        </Box>
                      </> :
                      <Link
                        to={`/event/${item._id}`}
                        // className="card-title h5 text-dark d-inline-block mb-2"
                        tabIndex={0}
                      >
                        {/* {item?.start_time &&
            new Date(item?.start_time).getTime() > new Date().getTime() ? (
              <span className="text-green">Book Now!</span>
              ) : (
                <span className="text-dark-red">Bookings closed!</span>
                )} */}
                        { }
                        <span className="text-green">Book Now!</span>
                      </Link>
                  ) : (
                    item?.schedule_status !== "cancelled" ?
                      <Button sx={{ marginLeft: "0px" }} disabled ><span style={{ fontSize: "16px", textTransform: "capitalize", marginTop: "-6px" }} className="text-dark-red">Bookings closed!</span></Button>
                      :
                      <Button disabled ><span style={{ fontSize: "16px", textTransform: "capitalize", marginTop: "-6px" }} className="text-dark-red">Event cancelled</span></Button>
                  )}
                  {/* <a href="#" className="link-hover-dark-primary"> */}
                  {/* {item.user} */}
                  {/* </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        : null}
    </>
  );
};

export default EventCard;
