import React, { useEffect, useState } from "react";
import BlogImage5 from "../../assets/image/blog/image-5.jpg";
import BlogCard from "../blog/BlogCard";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { GetAllEvent } from "../../redux/eventAction";
import Loader from "../../components/loader/Loader";
import NotFound from "../notFound/NotFound";
import EventCard from "../../components/eventCard/EventCard";
import { Link } from "react-router-dom";
import NotDataFound from "../notFound/NotDataFound";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#ff5a5f",
      border: "none",
      "&:hover": {
        color: "#ff5a5f",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#ff5a5f",
    },
  },
}));

const cardPerPage = 9;

const Events = () => {
  const classes = useStyles();
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);
  const [allGetEvent, setAllGetEvent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getEvent = async () => {
    try {
      const res = await GetAllEvent(cardPerPage, currentPage, "");
      if (res.status) {
        const data = res?.data?.eventData;
        if (Array.isArray(data) && data.length) {
          setAllGetEvent(res.data);
        } else {
          seterror(true);
        }
      } else {
        seterror(true);
      }
    } catch (err) {
      seterror(true);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getEvent();
  }, [currentPage]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotDataFound />;
  }

  const handleCurrentPageChange = (_, val) => {
    setCurrentPage(val);
  };

  return (
    <>
      { }
      <div
        id="page-title"
        className="page-title page-title-style-background"
        style={{ backgroundImage: `url(${BlogImage5})` }}
      >
        <div className="container">
          <div className="h-100 d-flex align-items-center">
            <div className="mb-2">
              <h1
                className="mb-0 fadeInDown animated"
                data-animate="fadeInDown"
              >
                <span>Events </span>
              </h1>
              <ul
                className="breadcrumb breadcrumb-style-01 fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li className="breadcrumb-item">
                  <Link to="/" className="link-hover-dark-primary">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span>Events</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="wrapper-content" className="wrapper-content">
        <div className="container">
          <div className="page-container">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all"
              >
                <div className="row">
                  {allGetEvent &&
                    allGetEvent?.eventData.filter((itemEvent) => {
                      return itemEvent.is_active && itemEvent?.approval_status !== 'rejected' && itemEvent?.approval_status !== 'pending' && itemEvent?.schedule_status !== 'cancelled';
                    }).length > 0 ?
                    allGetEvent?.eventData.filter((itemEvent) => {
                      return itemEvent.is_active && itemEvent?.approval_status !== 'rejected' && itemEvent?.approval_status !== 'pending' && itemEvent?.schedule_status !== 'cancelled';
                    })?.map((item) => {
                      return <EventCard item={item} />;
                    }) : <h3 style={{ fontSize: "40px !important", color:"black !important" }}>No event available</h3>}
                </div>
                {/* {allGetEvent && allGetEvent?.eventData?.filter((itemEvent) => {
                  return itemEvent.is_active && itemEvent?.approval_status !== 'rejected' && itemEvent?.approval_status !== 'pending' && itemEvent?.schedule_status !== 'cancelled';
                }).length > 0 ?
                  allGetEvent?.totalPages > 1 && ( */}
                    <Pagination
                      count={allGetEvent?.totalPages}
                      className={classes.pagination}
                      shape="rounded"
                      onChange={handleCurrentPageChange}
                    />
                  {/* ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
