import React, { useEffect, useState } from "react";
import logo from "../../assets/image/logo-home.svg";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goTopStyle = {
    zIndex: 1600,
    opacity: 0,
    visibility: "hidden",
    transition: "all 0.8s ease-in-out",
    transform: "translate3d(0, 200%, 0)",
    position: "fixed",
    bottom: "114px",
    right: {xs:"10px",sm:"40px",md:"40px",lg:"40px"},
    ...(isVisible && {
      opacity: 1,
      visibility: "visible",
      transform: "translate3d(0, 0, 0)",
    }),
  };

  const linkStyle = {
    textDecoration: "none",
    border: "0 none",
    display: "block",
    height: "3.6rem",
    width: "3.6rem",
    lineHeight: "3.6rem",
    textAlign: "center",
    backgroundPosition: "center center",
    backgroundSize: "10px 15px",
    backgroundColor: "rgb(8,8,8)",
    textTransform: "uppercase",
    borderRadius: "50%",
    transition: "all 0.3s ease-in-out",
    color: "white",
  };

  const linkHoverStyle = {
    backgroundColor: "#153E80",
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="main-footer main-footer-style-01 bg-pattern-01"
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <div className="footer-second">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-6 mb-6 mb-lg-0">
              <div className="mb-6">
                <img style={{ width: "300px" }} src={logo} alt="Thedir" />
              </div>
              <div className="mb-7">
                <div className="font-size-md font-weight-semibold text-dark mb-2">
                  Global Garner Community Pvt Ltd<br/>CIN : U70200GJ2024PTC149720
                </div>
                <p className="mb-0">
                  5th floor, Grand Emporio, Motera Stadium Road,
                  <br />
                  Ahmedabad, Gujarat
                  <br />
                  380005
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg mb-6 mb-lg-0">
              <div className="font-size-md font-weight-semibold text-dark mb-2">
                Company
              </div>
              <ul className="list-group list-group-flush list-group-borderless">
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/" className="link-hover-secondary-primary">
                    Home
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/blog" className="link-hover-secondary-primary">
                    Blog
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/about-us" className="link-hover-secondary-primary">
                    About Us
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/contact-us"
                    className="link-hover-secondary-primary"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg mb-6 mb-lg-0">
              <div className="font-size-md font-weight-semibold text-dark mb-2">
                Quick Links
              </div>
              <ul className="list-group list-group-flush list-group-borderless">
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link to="/faq" className="link-hover-secondary-primary">
                    FAQS
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/privacy-policy"
                    className="link-hover-secondary-primary"
                  >
                    Privacy
                  </Link>
                </li>
                <li className="list-group-item px-0 lh-1625 bg-transparent py-1">
                  <Link
                    to="/terms-and-condition"
                    className="link-hover-secondary-primary"
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-last mt-2 mt-md-2">
        <div className="container">
          <div className="footer-last-container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-4 mb-lg-0">
                <div className="social-icon text-dark">
                  <ul className="list-inline">
                    <li className="list-inline-item mr-5">
                      <a
                        target="_blank"
                        title="Instagram"
                        href="https://www.instagram.com/myggcommunity?igsh=cWR4Znc3NjBmcmht"
                      >
                        <InstagramIcon
                          sx={{
                            color: "#585858",
                            "&:hover": {
                              color: "black !important",
                            },
                          }}
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-5">
                      <a
                        target="_blank"
                        title="Youtube"
                        href="https://youtube.com/@GlobalGarnerOfficial?si=pLbPk5lraRX0Zog6"
                      >
                        {/* <i className="fab fa-youtube" />
                        <span>Youtube</span> */}
                        <YouTubeIcon
                          sx={{
                            color: "#585858",
                            "&:hover": {
                              color: "black !important",
                            },
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 mb-lg-0">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  © {currentYear}&#x2002;
                  <Link
                    to="/"
                    className="link-hover-dark-primary font-weight-semibold"
                  >
                    GGC&#x2002;
                  </Link>
                  All Rights Resevered.
                </div>
              </div>
              <Box  className="go-top" sx={goTopStyle}>
                <a
                  className="smoothscroll"
                  title="Back to Top"
                  href="#top"
                  onClick={handleScrollToTop}
                  style={{ ...linkStyle, ...(isVisible && linkHoverStyle) }}
                >
                  <ArrowUpwardIcon style={{ fontSize: "2rem" }} />
                </a>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
