import React, { useEffect, useState } from "react";
import BlogImage5 from "../../assets/image/blog/image-5.jpg";
import BlogCard from "./BlogCard";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { GetAllBlog } from "../../redux/blogAction";
import Loader from "../../components/loader/Loader";
import NotDataFound from "../notFound/NotDataFound";
import { Link } from "react-router-dom";
import BaseBlogCard from "../../components/slides/BaseBlogCard";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    "& .MuiPaginationItem-root": {
      margin: "0 2px",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#ff5a5f",
      border: "none",
      "&:hover": {
        color: "#ff5a5f",
      },
    },
    "& .MuiPaginationItem-page:hover": {
      color: "#ff5a5f",
    },
  },
}));
const cardPerPage = 9;
const WebBlog = () => {
  const classes = useStyles();

  const [allGetBlog, setAllGetBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);

  // const getBlog = async () => {
  //   const res = await GetAllBlog();
  //   setAllGetBlog(res.data);
  // };
  const getBlog = async () => {
    try {
      const res = await GetAllBlog(cardPerPage, currentPage, "");
      if (res.status) {
        const data = res?.data?.blogData;
        if (Array.isArray(data) && data.length) {
          setAllGetBlog(res.data);
        } else {
          seterror(true);
        }
      } else {
        seterror(true);
      }
    } catch (err) {
      seterror(true);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getBlog();
  }, [currentPage]);

  const handleCurrentPageChange = (_, val) => {
    setCurrentPage(val);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotDataFound />;
  }

  return (
    <div>
      <div
        id="page-title"
        className="page-title page-title-style-background"
        style={{ backgroundImage: `url(${BlogImage5})` }}
      >
        <div className="container">
          <div className="h-100 d-flex align-items-center">
            <div className="mb-2">
              <h1
                className="mb-0 fadeInDown animated"
                data-animate="fadeInDown"
              >
                <span>Some </span>
                <span className="font-weight-light">Tips &amp; Articles</span>
              </h1>
              <ul
                className="breadcrumb breadcrumb-style-01 fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li className="breadcrumb-item">
                  <Link to="/" className="link-hover-dark-primary">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span>Blog</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="wrapper-content" className="wrapper-content">
        <div className="container">
          <div className="page-container">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all"
              >
                <div className="row">
                  {allGetBlog &&
                    allGetBlog?.blogData?.map((item) => {
                      return <BaseBlogCard item={item} key={item._id} />;
                    })}
                </div>
                {allGetBlog?.totalPages > 1 && (
                  <Pagination
                    count={allGetBlog?.totalPages}
                    className={classes.pagination}
                    shape="rounded"
                    onChange={handleCurrentPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBlog;
