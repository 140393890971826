import React from "react";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1rem", // Smaller margin top for mobile view
});

const FindChapterBtn = styled(Link)(({ theme }) => ({
  backgroundColor: "#F07579",
  color: "#fff",
  borderRadius: "5px",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  "&:hover": {
    backgroundColor: "#e7484d !important",
    color: "white !important",
  },
  fontSize: "80%", // Smaller font size for mobile view
  [theme.breakpoints.up("md")]: {
    fontSize: "90%", // Adjusted font size for larger screens
  },
}));

const StyledContainer = styled("div")({
  background: "linear-gradient(45deg, #1F4182, #223366)", // Gradient background
  width: "100%", // Full width
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "20px 0px"
});

const BePartnerPageNavigation = ({ text, link, buttonTitle }) => {
  const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   navigate("/find-chapter");
  // };

  return (
    <StyledContainer style={{ minHeight: "50px" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={6} md={6} sm={6}>
          <Typography
            sx={{
              textAlign: "center",
              margin: 0,
              color: "#fff",
              padding: {
                xs: "1rem", // Adjusted font size for mobile view
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              fontSize: {
                xs: "1rem", // Adjusted font size for mobile view
                sm: "1rem",
                md: "1rem",
                lg: "1.2rem",
                xl: "1rem",
              },
              marginTop: {
                xs: "0em", // Adjusted font size for mobile view
                sm: "0rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={5} md={6} sm={6} lg={6}>
          <ButtonContainer
            sx={{
              marginTop: {
                xs: "1em !important", // Adjusted font size for mobile view
                sm: "0rem !important",
                md: "0rem !important",
                lg: "0rem !important",
                xl: "0rem !important",
              },
              marginBottom: {
                xs: "1em", // Adjusted font size for mobile view
                sm: "0rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
            }}
          >
            <FindChapterBtn
              to={link}
              tabIndex="0"
              // onClick={handleButtonClick}
              sx={{
                padding: {
                  xs: "0rem 1rem", // Adjusted font size for mobile view
                  sm: "0.4rem 1rem",
                  md: "0.4rem 1rem",
                  lg: "0.4rem 1rem",
                  xl: "0.4rem 1rem",
                },
                textAlign: {
                  xs: "center", // Adjusted font size for mobile view
                },
              }}
            >
              {buttonTitle}
            </FindChapterBtn>
          </ButtonContainer>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default BePartnerPageNavigation;
