import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import Input from "../../common/Input";
import { useFormik } from "formik";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Yup from "yup";
import SelectBox from "../../common/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllChapter, getChapterAll } from "../../redux/action/ChapterAction";
import {
  CreateBlog,
  GetBlogs,
  UpdateBlog,
} from "../../redux/action/BlogAction";
import axios from "axios";
import { ApiURL, snackBar } from "../../redux/common";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageIcon from "@mui/icons-material/Image";
import { PlayArrow } from "@mui/icons-material";

function MyCustomPlugin(editor) {
  editor.model.document.on("change:data", () => {
    const MAX_CHAR_COUNT = 2000;
    const data = editor.getData();
    const charCount = data.replace(/<[^>]*>/g, "").length;

    if (charCount > MAX_CHAR_COUNT) {
      // Trim data to MAX_CHAR_COUNT
      const trimmedData = data.substring(0, MAX_CHAR_COUNT);
      editor.setData(trimmedData);

      // Optionally, show an alert or message
      alert("Character limit reached!");
    }
  });
}

const CreateBlogDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("user_data")).token;

  const { title, description, blog_category, blog_images, chapter_id, _id } =
    location?.state?.eventData;
  const [selectedImage, setSelectedImage] = useState(blog_images || []);
  const [selectedImageError, setSelectedImageError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [imageAllowMsg, setImageAllowMsg] = useState();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    let totalFiles = selectedImage.length + files.length;
    setSelectedImageError(false);
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "image/avif",
      "image/avchd",
      "image/flv",
      "image/f4v",
      "image/swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const invalidFiles = files.filter((file) => {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    });

    if (invalidFiles.length > 0) {
      setImageAllowMsg(
        `Selected ${
          invalidFiles.length === 1
            ? `${invalidFiles.length} file is`
            : `${invalidFiles.length} files are`
        } not image type, Please selete only image type file`
      );
      return;
    }

    if (totalFiles > 20) {
      setSnackbarMessage("You can upload a maximum of 20 files.");
      setSnackbarOpen(true);
      const remainingSlots = 20 - selectedImage.length;
      const filesToAdd = files.slice(0, remainingSlots);
      setSelectedImage([...selectedImage, ...filesToAdd]);
      setImageAllowMsg(
        `You can only upload a maximum of 20 images. ${
          files.length - remainingSlots
        } image(s) were not added.`
      );
      return;
    } else {
      setSelectedImage([...selectedImage, ...files]);
      setImageAllowMsg("");
    }
    e.target.value = "";
  };

  // const chapterData = useSelector(
  //   (state) => state.ChapterReducer?.getAllChapter?.chapterData
  // );
  const chapterData = useSelector(
    (state) => state.ChapterReducer?.chapter?.chapterData
  );
  const { createLoading, updateLoading } = useSelector(
    (state) => state.EventReducer
  );

  useEffect(() => {
    // dispatch(getChapterAll(true));
    dispatch(GetAllChapter("", "", "", "", "", true));
  }, []);

  const allChapterData = chapterData
    ? [
        { value: "All", label: "All" },
        ...chapterData?.map((el) => {
          return { value: el._id, label: el.chapter_name };
        }),
      ].filter(Boolean)
    : [{ value: "All", label: "All" }];

  const [blogCategory, setBlogCategory] = useState([]);

  useEffect(() => {
    const getBlogCategory = async () => {
      try {
        await axios
          .get(`${ApiURL}blog-category/get-all-category`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const allBlogCategory =
              res &&
              res?.data?.data?.map((el) => {
                return { value: el._id, label: el.category_name };
              });
            setBlogCategory(allBlogCategory);
          });
      } catch (error) {
        console.log("Error : ", error.message);
      }
    };
    getBlogCategory();
  }, []);

  const removeSelectedImage = (indexToRemove) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }

    const remainingImg = imagesArray.filter((item, i) => {
      return i !== indexToRemove;
    });
    setSelectedImage(remainingImg);
    setImageAllowMsg("");
  };

  const changeSelectedImage = (e, changeIndex) => {
    const files = e.target.files;
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "svg",
      "webp",
      "avif",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mp4",
      "webm",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "video/mp4", // MIME type for MP4 videos
      "video/webm", // MIME type for WebM videos
      "video/avi", // MIME type for AVI videos
      "video/quicktime", // MIME type for MOV videos
      "video/x-matroska", // MIME type for MKV videos
      // Add more video types as needed
    ];

    const file = files[0];
    const fileType = file.type.split("/").pop().toLowerCase();

    if (allowedTypes.includes(fileType)) {
      const updatedImages = [...selectedImage];
      updatedImages[changeIndex] = file;
      setSelectedImage(updatedImages);
    } else {
      snackBar(
        "File type not allowed. Please select a valid image file.",
        false
      );
    }
  };

  const showImages = (selectedImage) => {
    let imagesArray = [];
    if (selectedImage instanceof FileList) {
      imagesArray = Array.from(selectedImage);
    } else {
      imagesArray = Array.from(selectedImage);
    }
    return (
      <>
        {imagesArray.map((item, i) => {
          let isImage = true;
          let fileType = "";

          const item_url = item instanceof File ? item?.name : item;
          if (typeof item_url === "string") {
            fileType = item_url.substring(item_url.lastIndexOf(".") + 1);
            isImage = fileType.match(
              /(JPEG|JPG|PNG|jpeg|jpg|png|svg|webp|avi|avif|avchd|flv|f4v|swf|mpeg-2)/i
            );
          }
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid lightgray",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex", flex: "1", alignItems: "center" }}>
                {isImage ? (
                  <a
                    target="_blank"
                    href={
                      typeof item === "string"
                        ? item
                        : URL.createObjectURL(item)
                    }
                  >
                    <ImageIcon sx={{ marginRight: "0.5rem" }} />
                    <img
                      key={i}
                      style={{ width: 80 }}
                      src={
                        typeof item === "string"
                          ? item
                          : URL.createObjectURL(item)
                      }
                      alt={`Selected Image ${i + 1}`}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Error loading image ${i + 1}:`, e);
                      }}
                    />
                  </a>
                ) : (
                  <a
                    target="_blank"
                    href={
                      typeof item === "string"
                        ? item
                        : URL.createObjectURL(item)
                    }
                  >
                    <PlayArrow sx={{ marginRight: "0.5rem" }} />
                    <video style={{ width: 80 }} loop muted playsInline>
                      <source
                        src={
                          typeof item === "string"
                            ? item
                            : URL.createObjectURL(item)
                        }
                        type={`video/${fileType}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </a>
                )}
              </div>
              <div style={{ marginLeft: "auto" }}>
                {" "}
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => removeSelectedImage(i)}
                >
                  X
                </Button>
                <br />
              </div>
              {location.state.formName === "Update Blog" ? (
                <div
                  style={{
                    marginLeft: "auto",
                    paddingLeft: "10px",
                    marginTop: "9px",
                  }}
                >
                  {" "}
                  {/* Using marginLeft: "auto" to push the button to the end */}
                  <input
                    type="file"
                    accept="image/*, video/*"
                    id={`change_images_${i}`}
                    name={`change_images_${i}`}
                    style={{ display: "none" }}
                    onChange={(e) => changeSelectedImage(e, i)}
                  />
                  <label htmlFor={`change_images_${i}`}>
                    <Button component="span" color="success" variant="outlined">
                      Change
                    </Button>
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </>
    );
  };

  const BlogSchema = Yup.object().shape({
    title: Yup.string()
      .required("Please enter blog title")
      .max(50, "Title should not be greater than 100 characters")
      .matches(
        /^[A-Za-z][A-Za-z\s.]*$/,
        "Blog must contain at least one alphabet character and without leading spaces"
      ),
    description: Yup.string()
      .required("Please enter blog description")
      .max(2000, "Description should not be greater than 2000 characters"),
    //blog_category: Yup.string().required("Please select blog category"),
    // chapter_id: Yup.array().required("Please select chapter").min(1, "Please at least one chapter select"),
  });

  const handleClose = () => {
    navigate("/admin-blog");
  };

  const formik = useFormik({
    initialValues: {
      title: title || "",
      description: description || "",
      // blog_category: blog_category || "",
      chapter_id: chapter_id || "All",
    },
    validationSchema: BlogSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!selectedImage?.length) {
        setSelectedImageError(true);
        return;
      }

      const body = {
        title: values.title,
        description: values.description,
        blog_category: "6571c5e22edc3f2ae8ebdb17",
        chapter_id: values.chapter_id,
        blog_images: selectedImage,
      };
      const formData = new FormData();

      if (location.state.formName === "Create Blog") {
        formData.append("title", body.title);
        if (body.chapter_id && body.chapter_id !== "All") {
          formData.append("chapter_id", body.chapter_id);
        }
        // formData.append("chapter_id", body.chapter_id);
        formData.append("blog_category", body.blog_category);
        if (body.description) formData.append("description", body.description);
        let imagesArray = [];
        imagesArray = Array.from(selectedImage);
        imagesArray.forEach((image, index) => {
          formData.append("blog_images", image);
        });
      } else {
        formData.append("title", body.title);
        if (body.chapter_id && body.chapter_id !== "All") {
          formData.append("chapter_id", body.chapter_id);
        }
        formData.append("description", body.description);
        formData.append("blog_category", body.blog_category);
        let imagesArray = [];
        if (selectedImage instanceof FileList) {
          imagesArray = Array.from(selectedImage);
        } else {
          selectedImage.forEach(function (element) {
            if (typeof element == "object") {
              imagesArray.push(element);
            }
          });
        }
        imagesArray.forEach((image, index) => {
          formData.append(`blog_images`, image);
        });
        let existImage = [];
        for (let i = 0; i < selectedImage.length; i++) {
          if (typeof selectedImage[i] == "string") {
            existImage.push(selectedImage[i]);
          }
        }
        if (existImage.length > 0) {
          formData.append(`blog_images`, JSON.stringify(existImage));
        }
      }

      const resetInitForm = () => {
        resetForm();
        setSelectedImage(null);
      };

      if (location.state.formName === "Create Blog") {
        for (let [key, value] of formData.entries()) {
        }
        await dispatch(CreateBlog(formData)).then(() => {
          resetInitForm();
          handleClose();
        });
      } else {
        await dispatch(UpdateBlog(_id, formData)).then((res) => {
          if (res && res.data.status === true) {
            navigate("/admin-blog");
            // dispatch(GetBlogs(1, "", 10));
          }
        });
      }
    },
  });

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem",
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/admin-blog")}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} xs={12} sm={12}>
            <Grid item md={12}>
              <Box component={Paper} padding={{ xs: 2, sm: 3 }} elevation={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography
                    textAlign="center"
                    fontWeight={900}
                    fontSize={28}
                    marginBottom={2}
                    color="black"
                  >
                    {location.state.formName}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        maxLength={100} // Adjusted maxLength to 100 characters
                        label="Blog Title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={
                          formik.touched.title && formik.errors.title ? (
                            <div className="err-msg err-msg-font">
                              {formik.errors.title}
                            </div>
                          ) : null
                        }
                      />
                      {/* {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "#f44336", marginTop: ".5rem" }}>
                          {formik.errors.title}
                        </div>
                      )} */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <div
                        style={{
                          marginTop: "3px",
                          borderRadius: "4px",
                          overflowWrap:
                            "break-word" /* Modern equivalent of word-wrap */,
                          wordBreak:
                            "break-word" /* Breaks words at arbitrary points if necessary */,
                          whiteSpace: "normal",
                        }}
                      >
                        <CKEditor
                          id="description"
                          name="description"
                          editor={ClassicEditor}
                          data={
                            formik.values.description
                              ? formik.values.description.substring(0, 2000)
                              : ""
                          }
                          config={{
                            placeholder: "Add Description",
                            // extraPlugins: [MyCustomPlugin],
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            formik.setFieldValue("description", data);
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("description", true);
                          }}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="err-msg-font">
                              {formik.errors.description}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        id="blog_category"
                        name="blog_category"
                        label="Blog Category"
                        ClassName="range-select-filter"
                        value={formik.values.blog_category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={blogCategory || []}
                        error={
                          formik.touched.blog_category &&
                          Boolean(formik.errors.blog_category)
                        }
                        touched={formik.touched.blog_category}
                      />
                      {formik.touched.blog_category &&
                        formik.errors.blog_category && (
                          <div className="err-msg-font">
                            {formik.errors.blog_category}
                          </div>
                        )}
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sm={12}>
                      <SelectBox
                        defaultValue={"All"}
                        id="chapter_id"
                        name="chapter_id"
                        label="Chapter"
                        ClassName="range-select-filter"
                        value={formik.values.chapter_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        options={allChapterData || []}
                        error={
                          formik.touched.chapter_id &&
                          Boolean(formik.errors.chapter_id)
                        }
                        touched={formik.touched.chapter_id}
                      />
                      {formik.touched.chapter_id &&
                        formik.errors.chapter_id && (
                          <div className="err-msg-font">
                            {formik.errors.chapter_id}
                          </div>
                        )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      sx={{
                        width: "100%",
                        maxHeight: "200px",
                        overflowX: "scroll",
                        scrollBehavior: "smooth",
                        marginTop: "40px",
                      }}
                    >
                      {selectedImage && selectedImage.length > 0
                        ? showImages(selectedImage)
                        : ""}
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                      <p
                        style={{
                          marginBottom: "-1em",
                          marginTop: "-1em",
                          color: "#d32f2f",
                        }}
                      >
                        {imageAllowMsg}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12} sm={12}>
                      <input
                        type="file"
                        multiple
                        accept="image/*, video/*"
                        id="service_images"
                        name="service_images"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="service_images">
                        <Button
                          component="span"
                          variant="contained"
                          color="inherit"
                          sx={{ marginTop: "1rem" }}
                        >
                          <ImageIcon sx={{ marginRight: "0.5rem" }} />
                          Upload Images
                        </Button>
                      </label>
                      {selectedImageError ? (
                        <div className="err-msg-font">
                          Please upload media files.
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {(
                    location.state.formName === "Create Event"
                      ? createLoading
                      : updateLoading
                  ) ? (
                    <Button sx={{ marginTop: 4 }} variant="contained">
                      <CircularProgress
                        style={{ width: "25px", height: "25px", color: "#fff" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{ marginTop: 4 }}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateBlogDetails;
