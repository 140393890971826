import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { removeHtmlTags } from "../../htmlToString/HtmlToString";
import ShowFirstImage from "../../../../components/showFirstImage/ShowFirstImage";
import { Box, Button } from "@mui/material";
import { compareDates } from "../../../../utils/dateUtility";

const fixDescription = (data) => {
  if (data?.length < 50) {
    return data;
  }
  return data?.slice(0, 50) + "...";
};

const CommonSlide = ({ item, variants }) => {
  return (
    <>
      <motion.div variants={variants} className="store card border-0 rounded-0">
        {item.is_active && item?.approval_status !== 'rejected' && item?.approval_status !== 'pending' && item?.schedule_status !== 'cancelled' ?
          <>
            <div className="position-relative store-image">
              {/* <Link to={`/event/${item._id}`} tabIndex={0}> */}

              <Box sx={{ display: "flex", justifyContent: "center", width: { xs: "100%", lg: "365px" }, height: { xs: "200px", lg: "300px" }, overflow: "hidden" }}>
                {item?.event_images && item.event_images.length > 0 && (
                  <ShowFirstImage
                    images={item.event_images}
                    height="100%"
                    width="100%"
                    link={`/event/${item._id}`}
                  />
                )}
              </Box>

              {/* </Link> */}
              <div className="image-content position-absolute d-flex align-items-center">
                <div className="content-left">
                  <div className="badge badge-primary">{item.bedge}</div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 pb-0 pt-3">
              <Link
                to={`/event/${item._id}`}
                className="card-title h5 text-dark d-inline-block mb-2"
                tabIndex={0}
              >
                <span className="letter-spacing-25">
                  {item?.event_name &&
                    (item?.event_name.length > 40
                      ? item?.event_name?.substring(0, 40) + "..."
                      : item?.event_name)}
                </span>
              </Link>
              <ul className="list-inline store-meta mb-4 font-size-sm d-flex align-items-center flex-wrap">
                <li className="list-inline-item"></li>
                <li className="list-inline-item">
                  <span className="mr-1">From</span>
                  <span className="text-danger font-weight-semibold">
                    ₹{item.price}
                  </span>
                </li>
                <li className="list-inline-item separate" />
                <li className="list-inline-item">
                  <a className="link-hover-secondary-primary" tabIndex={0}>
                    <span>{item.chapter_name}</span>
                  </a>
                </li>
              </ul>
              <div className="media">
                <div className="media-body lh-14 font-size-sm card-text-hide card-text-hide">
                  {item.event_description && removeHtmlTags(item.event_description)}
                </div>
              </div>
            </div>
            <ul className="list-inline card-footer rounded-0 border-top pt-3 mt-5 bg-transparent px-0 store-meta d-flex align-items-center">
              <li className="list-inline-item">
                <span className="d-inline-block mr-1">
                  <i className="fal fa-map-marker-alt"></i>
                </span>
                <a
                  className="text-secondary text-decoration-none link-hover-secondary-blue"
                  tabIndex={0}
                >
                  {item.city_name}, {item.country_name},{" "}
                </a>
              </li>
              <li className="list-inline-item separate"></li>
              <li className="list-inline-item">
                {item?.end_time &&
                  compareDates(new Date(item?.end_time).toUTCString(), new Date().toUTCString()) ? (
                  item?.event_registered ?
                    <>
                      <Box padding={{ xs: 0, sm: 0 }}>
                        <button
                          disabled
                          className="btn btn-primary btn-block"
                          type="button"
                        >
                          Booked
                        </button>
                      </Box>
                    </> :
                    <Link
                      to={`/event/${item._id}`}
                      // className="card-title h5 text-dark d-inline-block mb-2"
                      tabIndex={0}
                    >
                      <span className="text-green">Book Now!</span>
                    </Link>
                ) : (
                  item?.schedule_status !== "cancelled" ?
                    <Button disabled ><span style={{ fontSize: "16px", textTransform: "capitalize", marginTop: "0px" }} className="text-dark-red">Bookings closed!</span></Button>
                    :
                    <Button disabled ><span style={{ fontSize: "16px", textTransform: "capitalize", marginTop: "0px" }} className="text-dark-red">Event cancelled</span></Button>
                )}
              </li>
            </ul>
          </>
          : null}
      </motion.div >
    </>
  );
};

export default CommonSlide;
